import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import {
  BaseContainer,
  GAP_SMALL,
  SELECTED_OUTLINE,
} from '../../../../common/styles';

export const LifeEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${GAP_SMALL};

  width: 100px;
`;

export const TimeStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: ${GAP_SMALL};
`;

export const VerticalLine = styled.div`
  flex: 1;
  border-left: 2px dashed ${COLORS.PRIMARY};
`;

export const TimeEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_SMALL};
`;

export const TimeText = styled.span`
  font-weight: 700;
  font-size: 13px;
  font-family: 'Roboto Mono', serif;
  color: ${COLORS.BLACK};
`;

export interface EntriesContainerProps {
  isSelected?: boolean;
}

export const EntriesContainer = styled(BaseContainer)`
  flex: 1;

  ${(props: EntriesContainerProps) => props.isSelected && SELECTED_OUTLINE}
`;
