import styled from 'styled-components';
import { COLORS } from '../common/colors';
import { CONTAINER_OUTLINE, GAP_LARGE, GAP_SMALL } from '../common/styles';

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 8px 4px;
  gap: ${GAP_LARGE};
  flex-wrap: wrap;

  @media (min-width: 425px) {
    padding: 16px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: ${GAP_LARGE};

  outline: ${CONTAINER_OUTLINE};
  background-color: ${COLORS.WHITE};
  border-radius: 0 0 16px 16px;
`;

export const WrappingInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
  flex-wrap: wrap;
  flex: 1;

  width: calc(min(100%, 750px));
`;

export const HalfWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 49%;
  min-width: 300px;
`;

export const EditingPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
`;
