import moment from 'moment/moment';
import { BestOfResult } from '../../models';

export const groupResults = (
  results: BestOfResult[],
  options?: { maxItemPerGroup?: number },
): Record<string, BestOfResult[]> => {
  const groups: Record<string, BestOfResult[]> = {};
  results.forEach(result => {
    if (!groups[result.time_group]) groups[result.time_group] = [];
    if (
      options?.maxItemPerGroup &&
      groups[result.time_group].length >= options.maxItemPerGroup
    )
      return;
    groups[result.time_group].push(result);
  });
  return groups;
};

export const getGroupTitle = (groupKey: string): string => {
  if (!groupKey) return 'All';
  const [year, month] = groupKey.split('-');
  if (month)
    return `${moment()
      .month(Number(month) - 1)
      .format('MMMM')} ${year}`;
  return year;
};
