import {
  faBan,
  faCheck,
  faNoteSticky,
  faRotate,
  faSquarePlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { DOMAttributes } from 'react';
import {
  GreenIconButton,
  IconButton,
  RedIconButton,
  YellowIconButton,
} from '../common/styles';

export const SaveButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <GreenIconButton {...props}>
    <FontAwesomeIcon icon={faCheck} />
  </GreenIconButton>
);

export const CancelButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <YellowIconButton {...props}>
    <FontAwesomeIcon icon={faBan} />
  </YellowIconButton>
);

export const DeleteButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <RedIconButton {...props}>
    <FontAwesomeIcon icon={faTrash} />
  </RedIconButton>
);

export const CreateButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <GreenIconButton {...props}>
    <FontAwesomeIcon icon={faSquarePlus} />
  </GreenIconButton>
);

export const AddNoteButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <YellowIconButton {...props}>
    <FontAwesomeIcon icon={faNoteSticky} />
  </YellowIconButton>
);

export const ReplaceButton = (props: DOMAttributes<HTMLButtonElement>) => (
  <IconButton {...props}>
    <FontAwesomeIcon icon={faRotate} />
  </IconButton>
);
