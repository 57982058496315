import styled from 'styled-components';
import {
  GAP_MEDIUM,
  HIDE_INPUT_CONTROLS,
  IconButton,
  Input,
} from '../../common/styles';

export const BestOfContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  gap: ${GAP_MEDIUM};
`;

export const BestOfButton = styled(IconButton)`
  height: 100%;
`;

export const MaxPerGroupInput = styled(Input)`
  ${HIDE_INPUT_CONTROLS};
`;
