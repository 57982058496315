import dateFormat from 'dateformat';
import { SearchResult } from '../../models';
import { parseDate } from '../../network/utils';
import { TimeInterval, TimeOption } from './types';

export const generateTimeIntervals = (
  from: Date,
  to: Date,
  timeOption: TimeOption,
): TimeInterval[] => {
  if (to <= from) throw new Error('"from" date must be before "to" date');

  let start;
  const toAdd = { year: 0, month: 0, day: 0 };

  switch (timeOption) {
    case TimeOption.Day:
      start = from;
      toAdd.day = 1;
      break;
    case TimeOption.Week:
      start = new Date(
        from.getFullYear(),
        from.getMonth(),
        from.getDate() - from.getDay(),
      );
      toAdd.day = 7;
      break;
    case TimeOption.Month:
      start = new Date(from.getFullYear(), from.getMonth(), 1);
      toAdd.month = 1;
      break;
    case TimeOption.Year:
      start = new Date(from.getFullYear(), 0, 1);
      toAdd.year = 1;
      break;
  }

  let cursor = start;
  const result: TimeInterval[] = [];
  while (cursor < to) {
    const intervalEnd = new Date(
      cursor.getFullYear() + toAdd.year,
      cursor.getMonth() + toAdd.month,
      cursor.getDate() + toAdd.day,
    );
    result.push({ from: cursor, to: intervalEnd });
    cursor = intervalEnd;
  }
  return result;
};

export const getIntervalLabels = (
  intervals: TimeInterval[],
  timeOption: TimeOption,
): string[] => {
  return intervals.map(interval => {
    switch (timeOption) {
      case TimeOption.Day:
        return dateFormat(interval.from, 'd mmm yy');
      case TimeOption.Week:
        return dateFormat(interval.from, 'W yy');
      case TimeOption.Month:
        return dateFormat(interval.from, 'mmm yy');
      case TimeOption.Year:
        return dateFormat(interval.from, 'yyyy');
      default:
        throw new Error();
    }
  });
};

export const getCountsFromSearchResult = (
  intervals: TimeInterval[],
  searchResults: SearchResult[],
) => {
  const result = Array(intervals.length).fill(0);

  for (const searchResult of searchResults) {
    const date = parseDate(searchResult.date);
    const intervalIndex = intervals.findIndex(
      i => date >= i.from && date < i.to,
    );
    result[intervalIndex] += searchResult.quantity || 1;
  }

  return result;
};
