import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import {
  BaseContainer,
  GAP_SMALL,
  SELECTED_OUTLINE,
} from '../../../../common/styles';

export const ActivityTypeContainer = styled(BaseContainer)<{
  isSelected?: boolean;
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.isSelected && SELECTED_OUTLINE}
`;

export const FlagsGroup = styled.div`
  display: flex;
  gap: ${GAP_SMALL};
`;

export const EnabledFlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_SMALL};

  padding: 2px 6px;
  font-size: 10px;

  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 4px;

  color: ${COLORS.PRIMARY};

  .svg {
    color: ${COLORS.GREEN};
  }
`;
