import {
  faAngleLeft,
  faAngleRight,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { PaginateContainer } from './styles';

interface Props<T> {
  items: T[];
  itemsPerPage: number;
  renderPageItems: (pageItems: T[]) => JSX.Element;
}

export const Pagination = <T extends object>({
  items,
  itemsPerPage,
  renderPageItems,
}: Props<T>) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const pageItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const onPageChange = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {renderPageItems(pageItems)}
      <PaginateContainer>
        <ReactPaginate
          breakLabel={<FontAwesomeIcon icon={faEllipsis} />}
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
        />
      </PaginateContainer>
    </>
  );
};
