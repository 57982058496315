import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ApiClient } from '../network/api-client';
import { AuthenticatedContext } from './authenticated-context';

export const useApiCall = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthenticatedContext);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  const apiCall = useMemo(
    () =>
      async <T>(apiOperation: (apiClient: ApiClient) => Promise<T>) => {
        try {
          setPendingRequest(true);
          return await apiOperation(authContext.apiClient);
        } catch (e) {
          navigate('/error');
          throw e;
        } finally {
          setPendingRequest(false);
        }
      },
    [navigate, authContext],
  );

  return { pendingRequest, apiCall, apiClient: authContext.apiClient };
};
