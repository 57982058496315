import styled from 'styled-components';
import { GAP_LARGE, GAP_SMALL, Input } from '../../../../common/styles';

export const EditLifeEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_LARGE};
`;

export const EditTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_SMALL};
`;

export const TimeInput = styled(Input)`
  max-width: 100px;
  font-family: 'Roboto Mono', serif;
`;
