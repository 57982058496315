import React, { useContext, useEffect, useRef } from 'react';
import { Input } from '../../../../common/styles';
import { AuthenticatedContext } from '../../../../hooks/authenticated-context';
import { Activity } from '../../../../models';
import { ActivityTypeSelect } from '../../../activity-type-select';
import { EditActivityContainer } from './styles';

interface Props {
  value: Activity;
  onChange: (value: Activity) => void;
}

export const EditActivity = ({value, onChange}: Props) => {
  const authContext = useContext(AuthenticatedContext);
  const nameRef = useRef<HTMLInputElement>(null);

  const update = (updateFn: (currentValue: Activity) => void) => {
    updateFn(value);
    onChange(value);
  };

  useEffect(() => {
    nameRef.current?.focus();
  }, [nameRef]);

  return (
    <EditActivityContainer>
      <Input
        ref={nameRef}
        type="text"
        placeholder="Name"
        value={value.name}
        onChange={event =>
          update(activity => (activity.name = event.target.value))
        }
      />
      <ActivityTypeSelect
        placeholder="Type"
        apiClient={authContext.apiClient}
        value={value.activity_type}
        onChange={option =>
          update(activity => {
            activity.activity_type = option!;
            activity.activity_type_id = option!.id;
          })
        }
      />
    </EditActivityContainer>
  );
};
