import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Activities } from './pages/activities';
import { AuthWrapper } from './pages/auth-wrapper';
import { BestOf } from './pages/best-of';
import { CalendarPage } from './pages/calendar';
import { Charts } from './pages/charts';
import { Login } from './pages/login/login';
import { Search } from './pages/search';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthWrapper>
        <CalendarPage />
      </AuthWrapper>
    ),
    errorElement: <div>Page not found</div>,
  },
  {
    path: 'search',
    element: (
      <AuthWrapper>
        <Search />
      </AuthWrapper>
    ),
  },
  {
    path: 'activities',
    element: (
      <AuthWrapper>
        <Activities />
      </AuthWrapper>
    ),
  },
  {
    path: 'charts',
    element: (
      <AuthWrapper>
        <Charts />
      </AuthWrapper>
    ),
  },
  {
    path: 'best-of',
    element: (
      <AuthWrapper>
        <BestOf />
      </AuthWrapper>
    ),
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'error',
    element: <div>An error occurred, look in console to know more.</div>,
  },
]);

export const Router = () => <RouterProvider router={router} />;
