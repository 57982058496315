import styled from 'styled-components';
import { GAP_MEDIUM, GAP_SMALL } from '../../common/styles';

export const Logo = styled.img`
  width: 100%;
  max-width: 380px;
  height: auto;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const CreateDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: ${GAP_SMALL};

  text-align: center;
  margin-top: 32px;
`;

export const EditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_MEDIUM};
  flex: 1;

  min-width: min(100%, 400px);
`;
