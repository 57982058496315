import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import { GAP_SMALL } from '../../../../common/styles';

export const LifeEntryActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SMALL};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_SMALL};
`;

export const ActivityName = styled.div`
  flex: 1;
`;

export const DescriptionContainer = styled.div`
  color: ${COLORS.PRIMARY};
  white-space: pre-line;
`;

export const Quantity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 4px;

  line-height: 14px;
  min-width: 12px;
`;

export const Rating = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 2px;
  border: 1px solid ${COLORS.RATING};
  border-radius: 4px;

  line-height: 14px;

  svg {
    color: ${COLORS.RATING};
  }
`;
