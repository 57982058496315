import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { COLORS } from '../../common/colors';
import { ROTATE_ANIMATION } from '../../common/styles';

export const SpinnerIcon = styled(FontAwesomeIcon)`
  color: ${COLORS.PRIMARY};
  height: 2em;
  animation: ${ROTATE_ANIMATION} 1s linear infinite;
`;
