import styled from 'styled-components';
import { GAP_LARGE } from '../../common/styles';

export const MainContainer = styled.div`
  min-height: 98vh;

  display: flex;
  flex-direction: column;
  gap: ${GAP_LARGE};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 8px 4px;

  @media (min-width: 425px) {
    padding: 16px;
  }
`;

export const ChartWrapper = styled.div`
  flex-grow: 1;
  min-height: 400px;
`;
