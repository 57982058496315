import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import {
  GAP_SMALL,
  HIDE_INPUT_CONTROLS,
  Input,
} from '../../../../common/styles';
import { DescriptionSelect } from '../../../description-select';

export const EditLifeEntryActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SMALL};
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_SMALL};
`;

export const ActivityContainer = styled.div`
  flex: 1;
`;

export const ColoredDescriptionSelect = styled(DescriptionSelect)`
  color: ${COLORS.PRIMARY};
` as any as typeof DescriptionSelect;

export const QuantityRatingInput = styled(Input)`
  width: 50px;
  ${HIDE_INPUT_CONTROLS};
`;

export const NumberInputContainer = styled.div`
  position: relative;
`;

export const NumberInputIcon = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
`;

export const QuantityInputIcon = styled(NumberInputIcon)``;
export const RatingInputIcon = styled(NumberInputIcon)`
  color: ${COLORS.RATING};
`;
