import React from 'react';
import { Navigate } from 'react-router-dom';
import { MenuContainer, MenuSelect } from '../components/menu-select';
import { AuthenticatedContext } from '../hooks/authenticated-context';
import { buildApiClient } from '../network/api-client';
import { getStoredCredentials } from '../network/credentials-storage';

interface Props {
  children: JSX.Element;
}

export const AuthWrapper = ({ children }: Props) => {
  const credentials = getStoredCredentials();

  if (!credentials) return <Navigate to="/login" replace={true} />;
  return (
    <AuthenticatedContext.Provider
      value={{ apiClient: buildApiClient(credentials) }}
    >
      <MenuContainer>
        <MenuSelect />
      </MenuContainer>
      {children}
    </AuthenticatedContext.Provider>
  );
};
