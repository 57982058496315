import React from 'react';
import { Activity } from '../../../../models';
import { ActivityContainer, TypeContainer } from './styles';

interface Props {
  activity: Activity;
  isSelected?: boolean;
  onSelect?: (activity: Activity) => void;
}

export const DisplayActivity = ({ activity, isSelected, onSelect }: Props) => {
  return (
    <ActivityContainer
      isSelected={isSelected}
      onClick={() => {
        if (onSelect) onSelect(activity);
      }}
    >
      {activity.name}
      <TypeContainer>{activity.activity_type?.name}</TypeContainer>
    </ActivityContainer>
  );
};
