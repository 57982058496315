import React, { useCallback } from 'react';
import { DisplayActivity } from '../../components/life-history/display/activity';
import { DisplayActivityType } from '../../components/life-history/display/activity-type';
import { Pagination } from '../../components/pagination';
import { Activity, ActivityType } from '../../models';
import { HalfWidthContainer } from '../styles';
import { ActivityEditMode, ListActivityItem } from './index';
import { DisplayContainer, ItemsContainer } from './styles';

interface Props {
  activityItems: ListActivityItem[];
  activityEditMode: ActivityEditMode;
  selectedActivityItem?: ListActivityItem;
  onSelectionChange?: (activityItem: ListActivityItem) => void;
}

export const DisplayItems = ({
  activityItems,
  activityEditMode,
  selectedActivityItem,
  onSelectionChange,
}: Props) => {
  const onSelectItem = useCallback(
    (item: ListActivityItem) => {
      if (onSelectionChange) onSelectionChange(item);
    },
    [onSelectionChange],
  );

  return (
    <DisplayContainer>
      <Pagination
        items={activityItems}
        itemsPerPage={40}
        renderPageItems={pageItems => (
          <ItemsContainer>
            {pageItems.map(activityItem => (
              <HalfWidthContainer key={activityItem.id}>
                {activityEditMode === ActivityEditMode.TYPES ? (
                  <DisplayActivityType
                    activityType={activityItem as ActivityType}
                    isSelected={activityItem === selectedActivityItem}
                    onSelect={onSelectItem}
                  />
                ) : (
                  <DisplayActivity
                    activity={activityItem as Activity}
                    isSelected={activityItem === selectedActivityItem}
                    onSelect={onSelectItem}
                  />
                )}
              </HalfWidthContainer>
            ))}
          </ItemsContainer>
        )}
      />
    </DisplayContainer>
  );
};
