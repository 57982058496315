import { forwardRef } from 'react';
import { Props as SelectProps, SelectInstance } from 'react-select';
import { ActivityType } from '../models';
import { ApiClient } from '../network/api-client';
import { CustomAsyncSelect } from './custom-select';

interface Props extends SelectProps<ActivityType, false> {
  apiClient: ApiClient;
}

export const ActivityTypeSelect = forwardRef<
  SelectInstance<ActivityType>,
  Props
>((props, ref) => {
  const searchActivityTypes = async (input: string): Promise<ActivityType[]> =>
    props.apiClient.searchActivityTypes(input);
  return (
    <CustomAsyncSelect<ActivityType>
      ref={ref}
      getOptionLabel={a => a.name}
      getOptionValue={a => a.id.toString()}
      loadOptions={searchActivityTypes}
      {...props}
    />
  );
});
