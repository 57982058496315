export enum TimeOption {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export interface TimeInterval {
  from: Date;
  to: Date;
}
