import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DayItem } from '../../common/types';
import { DisplayDay } from '../../components/life-history/display/day';
import { useApiCall } from '../../hooks/api-call-wrapper';
import { Day, LifeEntry } from '../../models';
import {
  createLifeEntry,
  deleteLifeEntry,
  updateLifeEntry,
} from '../../network/operations';
import { EditPanel } from './edit-panel';

interface Props {
  selectedDay: Day;
}

export const DayContent = ({ selectedDay }: Props) => {
  const { pendingRequest, apiCall } = useApiCall();

  const [day, setDay] = useState<Day>(selectedDay);
  const [selectedItem, setSelectedItem] = useState<DayItem>();

  useEffect(() => {
    setDay(selectedDay);
  }, [selectedDay]);

  // Util functions
  const cancelEdit = () => setSelectedItem(undefined);
  const updateDay = useMemo(
    () => async (updateFn: (currentValue: Day) => Promise<Day>) => {
      cancelEdit();
      const updatedValue = await updateFn(day);
      setDay({ ...updatedValue });
    },
    [day],
  );

  // Callbacks
  const onSelectionChange = useCallback((item: DayItem) => {
    setSelectedItem(item);
  }, []);

  const onCancelEdit = useCallback(() => {
    cancelEdit();
  }, []);

  const onCreateLifeEntry = useCallback(
    async (memoryLifeEntry: LifeEntry) => {
      await updateDay(async day => {
        const savedLifeEntry = await apiCall<LifeEntry>(apiClient => {
          memoryLifeEntry.day_id = day.id;
          return createLifeEntry(apiClient, memoryLifeEntry);
        });
        day.life_entries.push(savedLifeEntry);
        return day;
      });
    },
    [apiCall, updateDay],
  );

  const onUpdateSelectedLifeEntry = useCallback(
    async (newLifeEntry: LifeEntry) => {
      await updateDay(async day => {
        const memoryLifeEntry = selectedItem as LifeEntry;
        const updatedLifeEntry = await apiCall<LifeEntry>(apiClient =>
          updateLifeEntry(apiClient, memoryLifeEntry, newLifeEntry),
        );
        const indexToReplace = day.life_entries.indexOf(memoryLifeEntry);
        day.life_entries[indexToReplace] = updatedLifeEntry;
        return day;
      });
    },
    [apiCall, updateDay, selectedItem],
  );

  const onDeleteSelectedLifeEntry = useCallback(async () => {
    await updateDay(async day => {
      const lifeEntryToDelete = selectedItem as LifeEntry;
      await apiCall(apiClient => deleteLifeEntry(apiClient, lifeEntryToDelete));
      const indexToRemove = day.life_entries.indexOf(lifeEntryToDelete);
      day.life_entries.splice(indexToRemove, 1);
      return day;
    });
  }, [apiCall, updateDay, selectedItem]);

  const onSaveNote = useCallback(
    async (note: string) => {
      await updateDay(async day => {
        await apiCall(async apiClient =>
          apiClient.updateDay({
            ...day,
            note: note,
          }),
        );

        day.note = note;
        return day;
      });
    },
    [apiCall, updateDay],
  );

  return (
    <>
      <DisplayDay
        day={day}
        pendingRequest={pendingRequest}
        selectedItem={selectedItem}
        onSelectionChange={onSelectionChange}
      />
      <EditPanel
        dayHasNote={!!day.note}
        dayItem={selectedItem}
        onCancel={onCancelEdit}
        onCreateLifeEntry={onCreateLifeEntry}
        onUpdateLifeEntry={onUpdateSelectedLifeEntry}
        onDeleteLifeEntry={onDeleteSelectedLifeEntry}
        onSaveNote={onSaveNote}
      />
    </>
  );
};
