import styled from 'styled-components';
import { COLORS } from '../../common/colors';
import { GAP_SMALL, IconButton } from '../../common/styles';

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: ${GAP_SMALL};
`;

export const SearchResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
  flex-wrap: wrap;

  width: 100%;
  margin-bottom: 12px;
`;

export const SearchButton = styled(IconButton)`
  height: 100%;
`;

export const SearchResultCountContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  font-weight: 700;
  font-size: 16px;
  color: ${COLORS.BLACK};
  text-transform: uppercase;
`;
