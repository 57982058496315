import { LifeEntry } from '../models';

export enum EditingModes {
  LIFE_ENTRY = 'Life Entry',
  NOTE = 'Note',
  ADD = 'Add',
}

export const getEditMode = (editValue?: DayItem): EditingModes =>
  editValue === undefined
    ? EditingModes.ADD
    : typeof editValue === 'string'
    ? EditingModes.NOTE
    : EditingModes.LIFE_ENTRY;

// LifeEntry or Day.note
export type DayItem = LifeEntry | string;
