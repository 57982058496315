import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import { BaseContainer, SELECTED_OUTLINE } from '../../../../common/styles';

export const ActivityContainer = styled(BaseContainer)<{
  isSelected?: boolean;
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.isSelected && SELECTED_OUTLINE}
`;

export const TypeContainer = styled.span`
  color: ${COLORS.PRIMARY};
  white-space: nowrap;
`;
