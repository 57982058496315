import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import { GAP_MEDIUM, H2, TextArea } from '../../../../common/styles';

export const EditReplaceActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_MEDIUM};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_MEDIUM};
`;

export const Title = styled(H2)`
  color: ${COLORS.GRAY};
`;

export const DescriptionInput = styled(TextArea)`
  color: ${COLORS.PRIMARY};
`;
