import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import {
  BaseContainer,
  GAP_MEDIUM,
  GAP_SMALL,
  IconButton,
} from '../../../../common/styles';

export const SearchResultContainer = styled(BaseContainer)``;

export const SearchResultLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${GAP_SMALL};
`;

export const LineGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_MEDIUM};
`;

export const TimeGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${GAP_SMALL};

  font-weight: 700;
  font-family: 'Roboto Mono', serif;
  color: ${COLORS.GRAY};
`;

export const DisplayActivityType = styled.span`
  color: ${COLORS.GRAY};
  margin-right: 8px;
`;

export const DisplayActivity = styled.span`
  color: ${COLORS.BLACK};
`;

export const SmallIconButton = styled(IconButton)`
  min-width: unset;
  min-height: unset;
  padding: 4px;
`;

export const DisplayDate = styled.span`
  white-space: nowrap;
`;
