import { faSnowplow, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Colors,
  Legend,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { ChartData } from 'chart.js/dist/types';
import { sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHotkeys } from 'react-hotkeys-hook';
import { GreenIconButton, H2, Input, RedIconButton } from '../../common/styles';
import { EnumOption, generateEnumOptions } from '../../common/utils';
import { ActivitySelect } from '../../components/activity-select';
import { ActivityTypeSelect } from '../../components/activity-type-select';
import { CustomSelect } from '../../components/custom-select';
import { DateInput } from '../../components/date-input';
import { useApiCall } from '../../hooks/api-call-wrapper';
import { Activity, ActivityType } from '../../models';
import { parseDate } from '../../network/utils';
import {
  HalfWidthContainer,
  PageHeader,
  WrappingInputsContainer,
} from '../styles';
import { ChartWrapper, MainContainer, PageContent } from './styles';
import { TimeOption } from './types';
import {
  generateTimeIntervals,
  getCountsFromSearchResult,
  getIntervalLabels,
} from './utils';

ChartJS.register(
  BarElement,
  CategoryScale,
  Colors,
  Legend,
  LinearScale,
  PointElement,
  Tooltip,
);

// This is the first date with data in the database
const DEFAULT_START_DATE = '2010-12-25';

const timeOptions = generateEnumOptions(TimeOption);

const CHART_OPTIONS: ChartOptions<'bar'> = {
  animations: {
    linear: {
      duration: 400,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    colors: {
      forceOverride: true,
    },
  },
};

export const Charts = () => {
  const { apiCall, apiClient } = useApiCall();

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [timeOption, setTimeOption] = useState<TimeOption>(TimeOption.Month);

  const [activityType, setActivityType] = useState<ActivityType | null>(null);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [text, setText] = useState<string>('');

  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    labels: [],
    datasets: [],
  });

  const canAddDataset = (): boolean =>
    !!activity || !!activityType || text.length >= 3;

  const addDataset = async () => {
    const searchResults = await apiCall(apiClient =>
      apiClient.search({
        activity_type_id: activityType?.id,
        activity_id: activity?.id,
        ...(fromDate ? { start_date: fromDate } : {}),
        ...(toDate ? { end_date: toDate } : {}),
        ...(text ? { text } : {}),
      }),
    );

    const displayFromDate = fromDate
      ? parseDate(fromDate)
      : parseDate(DEFAULT_START_DATE);
    const displayToDate = toDate ? parseDate(toDate) : new Date();
    const intervals = generateTimeIntervals(
      displayFromDate,
      displayToDate,
      timeOption,
    );

    setChartData({
      labels: getIntervalLabels(intervals, timeOption),
      datasets: [
        ...chartData.datasets,
        {
          label: `${[activityType?.name, activity?.name, text]
            .filter(i => i)
            .join(' - ')} (${sumBy(searchResults, r => r.quantity || 1)})`,
          data: getCountsFromSearchResult(intervals, searchResults),
        },
      ],
    });
  };

  const clearDatasets = () => {
    setChartData({ labels: chartData.labels, datasets: [] });
  };

  useEffect(clearDatasets, [fromDate, toDate, timeOption]);

  useHotkeys(
    'enter',
    async () => {
      if (canAddDataset()) await addDataset();
    },
    { enableOnFormTags: true },
    [fromDate, toDate, activityType, activity, text, chartData],
  );
  useHotkeys('escape', clearDatasets, { enableOnFormTags: true }, []);

  return (
    <MainContainer>
      <PageHeader>
        <H2>Charts</H2>
        <WrappingInputsContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="From"
              max={toDate}
              value={fromDate}
              onChange={event => {
                if (!toDate || event.target.value < toDate)
                  setFromDate(event.target.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="To"
              min={fromDate}
              value={toDate}
              onChange={event => {
                if (!fromDate || event.target.value > fromDate)
                  setToDate(event.target.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <CustomSelect<EnumOption<TimeOption>>
              getOptionLabel={a => a.value}
              getOptionValue={a => a.value}
              options={timeOptions}
              value={timeOptions.find(o => o.value === timeOption)}
              onChange={event => {
                if (event) setTimeOption(event.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivityTypeSelect
              placeholder="Type"
              apiClient={apiClient}
              isClearable
              value={activityType}
              onChange={o => setActivityType(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivitySelect
              placeholder="Activity"
              apiClient={apiClient}
              isClearable
              value={activity}
              onChange={o => setActivity(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <Input
              type="text"
              placeholder="Text"
              value={text}
              onChange={event => setText(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <RedIconButton
              onClick={clearDatasets}
              disabled={chartData.datasets.length === 0}
            >
              <FontAwesomeIcon icon={faSnowplow} />
            </RedIconButton>
          </HalfWidthContainer>
          <HalfWidthContainer>
            <GreenIconButton onClick={addDataset} disabled={!canAddDataset()}>
              <FontAwesomeIcon icon={faSquarePlus} />
            </GreenIconButton>
          </HalfWidthContainer>
        </WrappingInputsContainer>
      </PageHeader>
      <PageContent>
        <ChartWrapper>
          <Bar options={CHART_OPTIONS} data={chartData} />
        </ChartWrapper>
      </PageContent>
    </MainContainer>
  );
};
