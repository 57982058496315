import {
  faBars,
  faBaseballBatBall,
  faChartLine,
  faHouse,
  faListOl,
  faRightFromBracket,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { SingleValue } from 'react-select';
import styled from 'styled-components';
import { GAP_LARGE, IconButton } from '../common/styles';
import { EnumOption, generateEnumOptions } from '../common/utils';
import { clearCredentials } from '../network/credentials-storage';
import { CustomSelect } from './custom-select';

enum MenuOption {
  Home = 'Home',
  Activities = 'Activities',
  Search = 'Search',
  Charts = 'Charts',
  BestOf = 'Best Of',
  Logout = 'Logout',
}

const menuOptions = generateEnumOptions(MenuOption);

const getOptionIcon = (enumOption: MenuOption) => {
  switch (enumOption) {
    case MenuOption.Home:
      return faHouse;
    case MenuOption.Activities:
      return faBaseballBatBall;
    case MenuOption.Search:
      return faSearch;
    case MenuOption.Charts:
      return faChartLine;
    case MenuOption.BestOf:
      return faListOl;
    case MenuOption.Logout:
      return faRightFromBracket;
  }
};

const EmptyComponent = () => <></>;

export const MenuContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_LARGE};
`;

export const MenuSelect = () => {
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const onSelectMenuOption = (option: SingleValue<EnumOption<MenuOption>>) => {
    setMenuIsOpen(false);
    switch (option?.value) {
      case MenuOption.Home:
        navigate('/');
        break;
      case MenuOption.Activities:
        navigate('/activities');
        break;
      case MenuOption.Search:
        navigate('/search');
        break;
      case MenuOption.Charts:
        navigate('/charts');
        break;
      case MenuOption.BestOf:
        navigate('/best-of');
        break;
      case MenuOption.Logout:
        clearCredentials();
        navigate('/login');
        break;
    }
  };

  return (
    <div>
      <IconButton onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <CustomSelect<EnumOption<MenuOption>>
        components={{ Control: EmptyComponent }}
        getOptionLabel={o =>
          (
            <OptionContainer>
              <FontAwesomeIcon icon={getOptionIcon(o.value)} />
              {o.value}
            </OptionContainer>
          ) as unknown as string
        }
        getOptionValue={a => a.key}
        menuIsOpen={menuIsOpen}
        onChange={onSelectMenuOption}
        options={menuOptions}
      />
    </div>
  );
};
