import styled from 'styled-components';
import { GAP_SMALL } from '../../common/styles';

export const DateSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${GAP_SMALL};

  text-align: center;
`;

export const DateContainer = styled.div`
  width: 42px;
`;

export const MonthContainer = styled.div`
  width: 112px;
`;

export const YearContainer = styled.div`
  width: 60px;
`;
