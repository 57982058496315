import '@fontsource/poppins';
import '@fontsource/roboto-mono';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './common/colors';
import { HIDE_INPUT_CONTROLS } from './common/styles';
import { Router } from './router';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${COLORS.BACKGROUND};
    font-family: 'Poppins', serif;
    font-size: 14px;
  }

  ${HIDE_INPUT_CONTROLS}
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Router />
  </React.StrictMode>,
);
