import { isAxiosError } from 'axios';
import { isEqual, keyBy } from 'lodash';
import { isEqualOmit } from '../common/utils';
import {
  Day,
  LifeEntry,
  LifeEntryActivity,
  ReplaceActivity,
  ReplaceActivityPayload,
} from '../models';
import { ApiClient } from './api-client';
import { formatDate, formatTime } from './utils';

export const getDay = async (
  apiClient: ApiClient,
  date: Date,
): Promise<Day | undefined> => {
  try {
    return await apiClient.getDay(formatDate(date));
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }
    throw e;
  }
};

export const getActivityTypes = (apiClient: ApiClient, filter?: string) =>
  filter ? apiClient.searchActivityTypes(filter) : apiClient.getActivityTypes();

export const getActivities = async (
  apiClient: ApiClient,
  filter?: string,
  activityTypeId?: number,
) => {
  const activities = await (filter
    ? apiClient.searchActivities(filter)
    : apiClient.getActivities());
  return activityTypeId
    ? activities.filter(a => a.activity_type_id === activityTypeId)
    : activities;
};

const sanitizeLifeEntry = (lifeEntry: LifeEntry) => {
  if (lifeEntry.start_time?.length === 5)
    lifeEntry.start_time = formatTime(lifeEntry.start_time);
  if (lifeEntry.end_time?.length === 5)
    lifeEntry.end_time = formatTime(lifeEntry.end_time);
  return lifeEntry;
};

const createLifeEntryActivity = async (
  apiClient: ApiClient,
  lifeEntry: LifeEntry,
  lifeEntryActivity: LifeEntryActivity,
) => {
  lifeEntryActivity.life_entry_id = lifeEntry.id;
  const createdActivity = await apiClient.addLifeEntryActivity(
    lifeEntryActivity,
  );

  const indexToReplace =
    lifeEntry.life_entry_activities.indexOf(lifeEntryActivity);
  lifeEntry.life_entry_activities[indexToReplace] = createdActivity;
};

const createLifeEntryActivities = async (
  apiClient: ApiClient,
  lifeEntry: LifeEntry,
  lifeEntryActivities: LifeEntryActivity[],
) => {
  // We need to create them in sequence to make sure to preserve order
  for (const lifeEntryActivity of lifeEntryActivities) {
    await createLifeEntryActivity(apiClient, lifeEntry, lifeEntryActivity);
  }
};

export const createLifeEntry = async (
  apiClient: ApiClient,
  lifeEntry: LifeEntry,
): Promise<LifeEntry> => {
  const createdLifeEntry = await apiClient.addLifeEntry(
    sanitizeLifeEntry(lifeEntry),
  );
  createdLifeEntry.life_entry_activities = lifeEntry.life_entry_activities;
  await createLifeEntryActivities(
    apiClient,
    createdLifeEntry,
    lifeEntry.life_entry_activities,
  );
  return createdLifeEntry;
};

export const updateLifeEntry = async (
  apiClient: ApiClient,
  oldLifeEntry: LifeEntry,
  newLifeEntry: LifeEntry,
): Promise<LifeEntry> => {
  const oldActivitiesDictionary = keyBy(
    oldLifeEntry.life_entry_activities,
    'id',
  );
  const newActivitiesDictionary = keyBy(
    newLifeEntry.life_entry_activities,
    'id',
  );
  const activitiesToCreate = newLifeEntry.life_entry_activities.filter(
    l => !oldActivitiesDictionary[l.id],
  );
  const activitiesToUpdate = newLifeEntry.life_entry_activities.filter(
    l =>
      oldActivitiesDictionary[l.id] &&
      !isEqual(l, oldActivitiesDictionary[l.id]),
  );
  const activitiesToDelete = oldLifeEntry.life_entry_activities.filter(
    l => !newActivitiesDictionary[l.id],
  );

  const lifeEntryChanged = !isEqualOmit(
    oldLifeEntry,
    newLifeEntry,
    'life_entry_activities',
  );

  const promises: Promise<any>[] = [];
  if (lifeEntryChanged)
    promises.push(apiClient.updateLifeEntry(sanitizeLifeEntry(newLifeEntry)));
  promises.push(
    createLifeEntryActivities(apiClient, newLifeEntry, activitiesToCreate),
  );
  promises.push(
    ...activitiesToUpdate.map(a => apiClient.updateLifeEntryActivity(a)),
  );
  promises.push(
    ...activitiesToDelete.map(a => apiClient.deleteLifeEntryActivity(a)),
  );
  await Promise.all(promises);

  return newLifeEntry;
};

export const deleteLifeEntry = async (
  apiClient: ApiClient,
  lifeEntry: LifeEntry,
): Promise<void> => {
  const deleteActivitiesPromises = lifeEntry.life_entry_activities.map(
    lifeEntryActivity => apiClient.deleteLifeEntryActivity(lifeEntryActivity),
  );
  await Promise.all(deleteActivitiesPromises);
  await apiClient.deleteLifeEntry(lifeEntry);
};

export const execReplaceActivity = async (
  apiClient: ApiClient,
  replaceActivity: ReplaceActivity,
): Promise<void> => {
  const payload: ReplaceActivityPayload = {
    activity_ids: replaceActivity.activities.map(a => a.id),
    description: replaceActivity.description,
  };
  await apiClient.replaceActivity(replaceActivity.originalActivity, payload);
};
