import styled from 'styled-components';
import { COLORS } from '../../common/colors';

export const BestOfGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  min-width: calc(min(100%, 400px));
  max-width: 650px;

  border-radius: 4px;
  background-color: ${COLORS.WHITE};
`;

export const BestOfGroupHeader = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;

  padding: 10px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 4px 4px 0 0;

  color: ${COLORS.WHITE};
  font-size: 14px;
  font-weight: 700;
`;

export const BestOfGroupItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 0 4px 4px 4px;
  border-radius: 0 0 4px 4px;
`;

export const BestOfGroupItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  padding: 8px 6px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
`;

export const BestOfGroupItemRank = styled.div`
  width: 16px;

  color: ${COLORS.GRAY};
  font-size: 12px;
  font-weight: 600;
`;

export const BestOfGroupItemName = styled.div`
  flex: 1 0 0;

  color: ${COLORS.BLACK};
  font-size: 12px;
  font-weight: 400;
`;

export const BestOfGroupItemValue = styled.div`
  color: ${COLORS.BLACK};
  font-size: 12px;
  font-weight: 600;
  font-family: 'Roboto Mono', serif;
`;
