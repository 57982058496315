import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { H2, Input } from '../../common/styles';
import { randomNumber } from '../../common/utils';
import { ActivitySelect } from '../../components/activity-select';
import { ActivityTypeSelect } from '../../components/activity-type-select';
import { DateInput } from '../../components/date-input';
import { DisplaySearchResult } from '../../components/life-history/display/search-result';
import { Pagination } from '../../components/pagination';
import { Spinner } from '../../components/spinner';
import { useApiCall } from '../../hooks/api-call-wrapper';
import { Activity, ActivityType, SearchResult } from '../../models';
import {
  HalfWidthContainer,
  PageContent,
  PageHeader,
  WrappingInputsContainer,
} from '../styles';
import {
  SearchButton,
  SearchContainer,
  SearchResultCountContainer,
  SearchResultsContainer,
} from './styles';

export const Search = () => {
  const { pendingRequest, apiCall, apiClient } = useApiCall();

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [activityType, setActivityType] = useState<ActivityType | null>(null);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [text, setText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [estimatedDuration, setEstimatedDuration] = useState<string>('');

  const canSearch = (): boolean =>
    !!activity || !!activityType || text.length >= 3;
  const onSearch = async () => {
    const results = await apiCall(apiClient =>
      apiClient.search({
        activity_type_id: activityType?.id,
        activity_id: activity?.id,
        ...(fromDate ? { start_date: fromDate } : {}),
        ...(toDate ? { end_date: toDate } : {}),
        ...(text ? { text } : {}),
      }),
    );
    setSearchResults(results);
    getSumDuration(results);
  };

  useHotkeys(
    'enter',
    async () => {
      if (canSearch()) await onSearch();
    },
    { enableOnFormTags: true },
    [fromDate, toDate, activityType, activity, text],
  );

  const getSumDuration = (lifeEntries: SearchResult[]) => {
    if (!lifeEntries.length || lifeEntries.find(i => !i.end_time)) {
      setEstimatedDuration('');
      return;
    }

    let totalDuration = moment.duration();
    for (const lifeEntry of lifeEntries) {
      const startTime = moment.duration(lifeEntry.start_time);
      const endTime = moment.duration(lifeEntry.end_time);
      const difference = endTime.subtract(startTime);
      const duration =
        difference.asMinutes() < 0 ? difference.add('24:00') : difference;
      totalDuration = totalDuration.add(duration);
    }
    setEstimatedDuration(totalDuration.asHours().toFixed(2));
  };

  return (
    <div>
      <PageHeader>
        <H2>Search</H2>
        <WrappingInputsContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="From"
              max={toDate}
              value={fromDate}
              onChange={event => setFromDate(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="To"
              min={fromDate}
              value={toDate}
              onChange={event => setToDate(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivityTypeSelect
              placeholder="Type"
              apiClient={apiClient}
              isClearable
              value={activityType}
              onChange={o => setActivityType(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivitySelect
              placeholder="Activity"
              apiClient={apiClient}
              isClearable
              value={activity}
              onChange={o => setActivity(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <Input
              type="text"
              placeholder="Text"
              value={text}
              onChange={event => setText(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <SearchButton onClick={onSearch} disabled={!canSearch()}>
              <FontAwesomeIcon icon={faSearch} />
            </SearchButton>
          </HalfWidthContainer>
        </WrappingInputsContainer>
      </PageHeader>
      <PageContent>
        <SearchContainer>
          {pendingRequest && <Spinner />}
          {!pendingRequest && searchResults.length > 0 && (
            <>
              <SearchResultCountContainer>
                {estimatedDuration && `${estimatedDuration} hours - `}
                {`${searchResults.length} results`}
              </SearchResultCountContainer>
              <Pagination
                items={searchResults}
                itemsPerPage={20}
                renderPageItems={items => (
                  <SearchResultsContainer>
                    {items.map(i => (
                      <HalfWidthContainer key={randomNumber()}>
                        <DisplaySearchResult searchResult={i} />
                      </HalfWidthContainer>
                    ))}
                  </SearchResultsContainer>
                )}
              />
            </>
          )}
        </SearchContainer>
      </PageContent>
    </div>
  );
};
