import styled from 'styled-components';
import { COLORS } from '../../common/colors';
import {
  BORDER_RADIUS,
  CONTAINER_OUTLINE,
  GAP_SMALL,
} from '../../common/styles';

export const PaginateContainer = styled.div`
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: row;
    gap: ${GAP_SMALL};
    flex-wrap: wrap;

    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    color: ${COLORS.BLACK};
    background-color: ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS};
    outline: ${CONTAINER_OUTLINE};

    cursor: pointer;

    a {
      position: relative;
      display: block;
      padding: 8px;
      min-width: 26px;
      text-align: center;
    }

    &.previous,
    &.next {
      color: ${COLORS.BLACK};
    }

    &.selected {
      color: ${COLORS.WHITE};
      background-color: ${COLORS.PRIMARY};
    }

    &.disabled {
      cursor: inherit;
      color: ${COLORS.GRAY};
    }
  }
`;
