import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { H1, H2 } from '../../common/styles';
import { DayItem, EditingModes, getEditMode } from '../../common/types';
import { createLifeEntry, isLifeEntryValid } from '../../common/utils';
import {
  AddNoteButton,
  CancelButton,
  CreateButton,
  DeleteButton,
  SaveButton,
} from '../../components/buttons';
import { EditLifeEntry } from '../../components/life-history/editing/edit-life-entry';
import { EditNote } from '../../components/life-history/editing/edit-note';
import { LifeEntry } from '../../models';
import { ButtonsGroup, EditingPanel } from '../styles';
import { EditionContainer } from './styles';

interface Props {
  dayItem?: DayItem;
  dayHasNote: boolean;

  onCreateLifeEntry: (lifeEntry: LifeEntry) => void;
  onUpdateLifeEntry: (lifeEntry: LifeEntry) => void;
  onDeleteLifeEntry: () => void;
  onSaveNote: (note: string) => void;
  onCancel: () => void;
}

const canAddLifeEntry = (editingMode: EditingModes) => {
  return editingMode === EditingModes.ADD;
};

const canSaveLifeEntry = (editingMode: EditingModes, item?: DayItem) => {
  return (
    editingMode === EditingModes.LIFE_ENTRY &&
    item &&
    isLifeEntryValid(item as LifeEntry)
  );
};

const canAddNote = (editingMode: EditingModes, dayHasNote: boolean) => {
  return editingMode === EditingModes.ADD && !dayHasNote;
};

const canSaveNote = (editingMode: EditingModes) => {
  return editingMode === EditingModes.NOTE;
};

const canCancel = (editingMode: EditingModes) => {
  return editingMode !== EditingModes.ADD;
};

const canDelete = (editingMode: EditingModes, isCreate: boolean) => {
  return editingMode === EditingModes.LIFE_ENTRY && !isCreate;
};

export const EditPanel = ({
  dayHasNote,
  dayItem,
  onCancel,
  onDeleteLifeEntry,
  onCreateLifeEntry,
  onUpdateLifeEntry,
  onSaveNote,
}: Props) => {
  const [editingItem, setEditingItem] = useState<DayItem | undefined>();
  const [isCreate, setIsCreate] = useState<boolean>(false);

  useEffect(() => {
    setIsCreate(false);
    setEditingItem(dayItem ? cloneDeep(dayItem) : undefined);
  }, [dayItem]);

  useHotkeys(
    'enter',
    () => {
      const editingMode = getEditMode(editingItem);
      if (canAddLifeEntry(editingMode)) addLifeEntry();
    },
    { enableOnFormTags: ['input'] },
    [editingItem],
  );

  useHotkeys(
    'shift+enter',
    () => {
      const editingMode = getEditMode(editingItem);
      if (canSaveLifeEntry(editingMode, editingItem)) saveLifeEntry();
      if (canSaveNote(editingMode)) saveNote();
    },
    { enableOnFormTags: true },
    [editingItem],
  );

  useHotkeys(
    'n',
    () => {
      const editingMode = getEditMode(editingItem);
      if (canAddNote(editingMode, dayHasNote)) addNote();
    },
    { keyup: true }, // To avoid inputting the character in the note input
    [dayHasNote, editingItem],
  );

  useHotkeys(
    'escape',
    () => {
      const editingMode = getEditMode(editingItem);
      if (canCancel(editingMode)) cancel();
    },
    { enableOnFormTags: true },
    [editingItem],
  );

  useHotkeys(
    'shift+delete',
    () => {
      const editingMode = getEditMode(editingItem);
      if (canDelete(editingMode, isCreate)) deleteLifeEntry();
    },
    { enableOnFormTags: true },
    [editingItem, isCreate],
  );

  const addLifeEntry = () => {
    setIsCreate(true);
    setEditingItem(createLifeEntry());
  };
  const addNote = () => {
    setEditingItem('');
  };
  const saveLifeEntry = () => {
    if (isCreate) {
      onCreateLifeEntry(editingItem as LifeEntry);
    } else {
      onUpdateLifeEntry(editingItem as LifeEntry);
    }
    setEditingItem(undefined);
  };
  const saveNote = () => {
    onSaveNote(editingItem as string);
    setEditingItem(undefined);
  };
  const cancel = () => {
    setEditingItem(undefined);
    onCancel();
  };
  const deleteLifeEntry = () => {
    onDeleteLifeEntry();
    setEditingItem(undefined);
  };

  const editingMode = getEditMode(editingItem);
  return (
    <EditionContainer>
      <EditingPanel>
        <div>
          <H1>Editing</H1>
          <H2>{editingMode}</H2>
        </div>
        <ButtonsGroup>
          {canAddLifeEntry(editingMode) && (
            <CreateButton onClick={addLifeEntry} />
          )}
          {canAddNote(editingMode, dayHasNote) && (
            <AddNoteButton onClick={addNote} />
          )}
          {canSaveLifeEntry(editingMode, editingItem) && (
            <SaveButton onClick={saveLifeEntry} />
          )}
          {canSaveNote(editingMode) && <SaveButton onClick={saveNote} />}
          {canCancel(editingMode) && <CancelButton onClick={cancel} />}
          {canDelete(editingMode, isCreate) && (
            <DeleteButton onClick={deleteLifeEntry} />
          )}
        </ButtonsGroup>
      </EditingPanel>
      {editingMode === EditingModes.LIFE_ENTRY && (
        <EditLifeEntry
          value={editingItem as LifeEntry}
          onChange={value => setEditingItem({ ...value })}
        />
      )}
      {editingMode === EditingModes.NOTE && (
        <EditNote
          value={editingItem as string}
          onChange={event => setEditingItem(event.target.value)}
        />
      )}
    </EditionContainer>
  );
};
