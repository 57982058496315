export const COLORS = {
  BACKGROUND: '#F4F5FA',
  BLACK: '#060F27',
  GRAY: '#AEB8CF',
  GREEN: '#7FB964',
  HIGHLIGHT: '#B8DDE6',
  OUTLINE: '#D9D9D9',
  PRIMARY: '#3A9DB3',
  RATING: '#DAA520',
  RED: '#E15353',
  WHITE: '#FFFFFF',
  YELLOW: '#E1A853',
};
