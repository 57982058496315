import { faLink, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dateFormat from 'dateformat';
import React from 'react';
import { useNavigate } from 'react-router';
import Arrow from '../../../../assets/arrow.svg';
import { SearchResult } from '../../../../models';
import { parseDate } from '../../../../network/utils';
import {
  DescriptionContainer,
  Quantity,
  Rating,
} from '../life-entry-activity/styles';
import {
  DisplayActivity,
  DisplayActivityType,
  DisplayDate,
  LineGroup,
  SearchResultContainer,
  SearchResultLine,
  SmallIconButton,
  TimeGroup,
} from './styles';

interface Props {
  searchResult: SearchResult;
}

export const DisplaySearchResult = ({ searchResult }: Props) => {
  const navigate = useNavigate();
  const displayDate = dateFormat(parseDate(searchResult.date), 'd mmmm yyyy');
  return (
    <SearchResultContainer>
      <SearchResultLine>
        <LineGroup>
          <DisplayActivity>
            <DisplayActivityType>
              {searchResult.activity_type_name}
            </DisplayActivityType>
            {searchResult.activity_name}
          </DisplayActivity>
        </LineGroup>
        <LineGroup>
          <TimeGroup>
            {searchResult.start_time.substring(0, 5)}
            {searchResult.end_time && (
              <>
                <img src={Arrow} alt="arrow" />
                {searchResult.end_time.substring(0, 5)}
              </>
            )}
          </TimeGroup>
          {searchResult.quantity > 1 && (
            <Quantity>{searchResult.quantity}</Quantity>
          )}
          {searchResult.rating > 0 && (
            <Rating>
              {searchResult.rating}
              <FontAwesomeIcon icon={faStar} />
            </Rating>
          )}
        </LineGroup>
      </SearchResultLine>
      <SearchResultLine>
        <DescriptionContainer>{searchResult.description}</DescriptionContainer>
        <LineGroup>
          <DisplayDate>{displayDate}</DisplayDate>
          <SmallIconButton
            onClick={() => navigate(`/?date=${searchResult.date}`)}
          >
            <FontAwesomeIcon icon={faLink} />
          </SmallIconButton>
        </LineGroup>
      </SearchResultLine>
    </SearchResultContainer>
  );
};
