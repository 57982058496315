import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ActivityType } from '../../../../models';
import {
  ActivityTypeContainer,
  EnabledFlagContainer,
  FlagsGroup,
} from './styles';

interface Props {
  activityType: ActivityType;
  isSelected?: boolean;
  onSelect?: (activityType: ActivityType) => void;
}

export const DisplayActivityType = ({
  activityType,
  isSelected,
  onSelect,
}: Props) => {
  return (
    <ActivityTypeContainer
      isSelected={isSelected}
      onClick={() => {
        if (onSelect) onSelect(activityType);
      }}
    >
      {activityType.name}
      <FlagsGroup>
        {activityType.show_quantity && (
          <EnabledFlagContainer>
            Quantity
            <FontAwesomeIcon icon={faCheck} />
          </EnabledFlagContainer>
        )}
        {activityType.show_rating && (
          <EnabledFlagContainer>
            Rating
            <FontAwesomeIcon icon={faCheck} />
          </EnabledFlagContainer>
        )}
      </FlagsGroup>
    </ActivityTypeContainer>
  );
};
