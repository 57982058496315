import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
import { GAP_MEDIUM, GAP_SMALL } from '../../../../common/styles';

export const EditActivityTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SMALL};
`;

export const InputsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_MEDIUM};
`;

export const Label = styled.span`
  color: ${COLORS.GRAY};
`;
