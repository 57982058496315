import TextareaAutosize from 'react-textarea-autosize';
import styled, { css, keyframes } from 'styled-components';
import { COLORS } from './colors';

export const CONTAINER_OUTLINE = `1px solid ${COLORS.OUTLINE}`;
export const SELECTED_OUTLINE = `outline: 1px dashed ${COLORS.PRIMARY};`;

export const GAP_SMALL = '6px';
export const GAP_MEDIUM = '10px';
export const GAP_LARGE = '14px';

export const BORDER_RADIUS_NUMBER = 4;
export const BORDER_RADIUS = `${BORDER_RADIUS_NUMBER}px`;

export const ROTATE_ANIMATION = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const HIDE_INPUT_CONTROLS = css`
  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.BLACK};
  text-transform: uppercase;
  margin: 0;
`;

export const H2 = styled.h2`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${COLORS.PRIMARY};
  text-transform: uppercase;
  margin: 0;
`;

export const HR = styled.hr`
  width: 100%;
  border: 1px solid ${COLORS.OUTLINE};
  margin: 0;
`;

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: ${GAP_SMALL};

  background: ${COLORS.WHITE};
  outline: ${CONTAINER_OUTLINE};
  border-radius: ${BORDER_RADIUS};
`;

export const IconButton = styled.button`
  cursor: pointer;
  padding: 8px;
  height: fit-content;
  min-height: 32px;
  min-width: 32px;

  border: none;
  border-radius: ${BORDER_RADIUS};

  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};

  &:hover {
    -webkit-filter: brightness(90%);
  }

  &:active {
    -webkit-filter: brightness(110%);
  }

  &:disabled {
    cursor: inherit;
    -webkit-filter: saturate(0%) brightness(130%);
  }
`;

export const GreenIconButton = styled(IconButton)`
  background-color: ${COLORS.GREEN};
`;

export const YellowIconButton = styled(IconButton)`
  background-color: ${COLORS.YELLOW};
`;

export const RedIconButton = styled(IconButton)`
  background-color: ${COLORS.RED};
`;

export const InputCSS = css`
  background: ${COLORS.WHITE};
  padding: 8px 12px;

  border: none;
  border-radius: ${BORDER_RADIUS};
  outline: ${CONTAINER_OUTLINE};

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  ::placeholder {
    color: ${COLORS.GRAY};
  }
`;

export const Input = styled.input`
  ${InputCSS};
`;

export const TextArea = styled(TextareaAutosize)`
  ${InputCSS};

  min-height: 21px;
  resize: none;
  overflow: hidden;
`;
