import styled, { css } from 'styled-components';
import { COLORS } from '../../common/colors';
import { GAP_LARGE, GAP_SMALL, IconButton } from '../../common/styles';

const FILTERS_MIN_WIDTH = '295px';

export const ToggleButton = styled(IconButton)<{ isActive: boolean }>`
  width: 100px;
  font-weight: 700;
  text-transform: uppercase;
  outline: 1px solid ${COLORS.OUTLINE};

  background-color: ${COLORS.WHITE};
  color: ${COLORS.PRIMARY};

  ${props =>
    props.isActive &&
    css`
      cursor: inherit;
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};

      &:hover {
        -webkit-filter: none;
      }

      &:active {
        -webkit-filter: none;
      }
    `}
`;

export const HeaderLineContainer = styled.div`
  display: flex;
  gap: ${GAP_SMALL};
  align-items: center;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${GAP_SMALL};

  > * {
    min-width: ${FILTERS_MIN_WIDTH};
    box-sizing: border-box;
  }
`;

export const FilterButton = styled(IconButton)`
  height: 38px;
`;

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_LARGE};
  flex: 1;

  min-width: min(100%, 400px);
`;

export const EditingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_LARGE};
  flex: 1;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 24px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GAP_SMALL};
  flex-wrap: wrap;
`;
