import React, { useContext } from 'react';
import { BaseContainer, HR } from '../../../../common/styles';
import { randomNumber } from '../../../../common/utils';
import { AuthenticatedContext } from '../../../../hooks/authenticated-context';
import { ReplaceActivity } from '../../../../models';
import { ActivitySelect } from '../../../activity-select';
import { DisplayLifeEntryActivity } from '../../display/life-entry-activity';
import {
  DescriptionInput,
  EditReplaceActivityContainer,
  Title,
  TitleContainer,
} from './styles';

interface Props {
  value: ReplaceActivity;
  onChange: (value: ReplaceActivity) => void;
}

export const EditReplaceActivity = ({ value, onChange }: Props) => {
  const authContext = useContext(AuthenticatedContext);

  const update = (updateFn: (currentValue: ReplaceActivity) => void) => {
    updateFn(value);
    onChange(value);
  };

  return (
    <EditReplaceActivityContainer>
      <TitleContainer>
        <Title>Replacing</Title>
        {value.originalActivity.name}
      </TitleContainer>
      <DescriptionInput
        value={value.description}
        placeholder="Description"
        onChange={event =>
          update(
            activityToReplace =>
              (activityToReplace.description = event.target.value),
          )
        }
      />
      {value.activities.length > 0 && <Title>Preview</Title>}
      {value.activities.map((activity, activityIndex) => (
        <BaseContainer key={activity.id}>
          <DisplayLifeEntryActivity
            lifeEntryActivity={{
              id: randomNumber(),
              activity,
              activity_id: activity.id,
              description:
                activityIndex === 0
                  ? `${value.description} <original-description>`
                  : '',
            }}
          />
        </BaseContainer>
      ))}
      <HR />
      <ActivitySelect
        apiClient={authContext.apiClient}
        placeholder="Choose Activity"
        value={null}
        onChange={activity =>
          update(activityToReplace =>
            activityToReplace.activities.push(activity!),
          )
        }
      />
    </EditReplaceActivityContainer>
  );
};
