import React, { ForwardedRef, forwardRef } from 'react';
import Select, { Props, SelectInstance, Theme } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import styled, { css } from 'styled-components';
import { COLORS } from '../common/colors';
import {
  BORDER_RADIUS,
  BORDER_RADIUS_NUMBER,
  CONTAINER_OUTLINE,
} from '../common/styles';

const CLASS_NAME_PREFIX = 'Select';
const OPTION_HORIZONTAL_MARGIN_PX = 4;

export const SELECT_STYLES = css`
  .${CLASS_NAME_PREFIX}__control {
    outline: ${CONTAINER_OUTLINE} !important;
    border: none;
  }

  .${CLASS_NAME_PREFIX}__dropdown-indicator {
    display: none;
  }

  .${CLASS_NAME_PREFIX}__indicator-separator {
    display: none;
  }

  .${CLASS_NAME_PREFIX}__menu {
    outline: ${CONTAINER_OUTLINE};
    border: none;
    border-radius: ${BORDER_RADIUS};

    width: max-content;
    min-width: 100%;
  }

  .${CLASS_NAME_PREFIX}__menu-list {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${COLORS.PRIMARY};
      border-radius: 2px;
    }
  }

  .${CLASS_NAME_PREFIX}__option {
    margin: 2px ${OPTION_HORIZONTAL_MARGIN_PX}px;
    border-radius: ${BORDER_RADIUS};
    padding: 6px 8px;
    width: calc(100% - ${OPTION_HORIZONTAL_MARGIN_PX * 2}px);
  }

  .${CLASS_NAME_PREFIX}__placeholder {
    color: ${COLORS.GRAY};
  }

  .${CLASS_NAME_PREFIX}__single-value {
    color: inherit;
  }

  .${CLASS_NAME_PREFIX}__input-container {
    color: inherit;
  }
`;

const StyledSelect = styled(Select)`
  ${SELECT_STYLES};
` as any as Select;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${SELECT_STYLES};
` as any as AsyncSelect;

const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
  ${SELECT_STYLES};
` as any as AsyncCreatableSelect;

const setTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: BORDER_RADIUS_NUMBER,
  colors: {
    ...theme.colors,
    primary: COLORS.PRIMARY,
    primary25: COLORS.HIGHLIGHT,
    primary50: COLORS.HIGHLIGHT,
  },
});

export const CustomSelect = forwardRef<any, Props>(
  <Option, IsMulti extends boolean = false>(
    props: Props<Option, IsMulti>,
    ref: ForwardedRef<SelectInstance<Option, IsMulti>>,
  ) => (
    <StyledSelect<Option, IsMulti>
      ref={ref}
      classNamePrefix={CLASS_NAME_PREFIX}
      theme={setTheme}
      {...props}
    />
  ),
) as Select;

export const CustomAsyncSelect = forwardRef<any, Props>(
  <Option, IsMulti extends boolean = false>(
    props: Props<Option, IsMulti>,
    ref: ForwardedRef<SelectInstance<Option, IsMulti>>,
  ) => (
    <StyledAsyncSelect<Option, IsMulti>
      ref={ref}
      classNamePrefix={CLASS_NAME_PREFIX}
      theme={setTheme}
      {...props}
    />
  ),
) as AsyncSelect;

export const CustomAsyncCreatableSelect = forwardRef<any, Props>(
  <Option, IsMulti extends boolean = false>(
    props: Props<Option, IsMulti>,
    ref: ForwardedRef<SelectInstance<Option, IsMulti>>,
  ) => (
    <StyledAsyncCreatableSelect<Option, IsMulti>
      ref={ref}
      classNamePrefix={CLASS_NAME_PREFIX}
      theme={setTheme}
      {...props}
    />
  ),
) as AsyncCreatableSelect;
