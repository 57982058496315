import { useEffect, useRef } from 'react';
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';
import { TextArea } from '../../../common/styles';

export const EditNote = (props: TextareaAutosizeProps) => {
  const noteRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    noteRef.current?.focus();
  }, []);

  return <TextArea ref={noteRef} {...props} />;
};
