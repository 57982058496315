import React, { useContext, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SelectInstance } from 'react-select';
import Arrow from '../../../../assets/arrow.svg';
import { HR } from '../../../../common/styles';
import { createLifeEntryActivity } from '../../../../common/utils';
import { AuthenticatedContext } from '../../../../hooks/authenticated-context';
import { Activity, LifeEntry } from '../../../../models';
import { ActivitySelect } from '../../../activity-select';
import { EditLifeEntryActivity } from '../edit-life-entry-activity';
import { EditLifeEntryContainer, EditTimeContainer, TimeInput } from './styles';

interface Props {
  value: LifeEntry;
  onChange: (value: LifeEntry) => void;
}

export const EditLifeEntry = ({ value, onChange }: Props) => {
  const authContext = useContext(AuthenticatedContext);
  const newActivityRef = useRef<SelectInstance<Activity>>(null);

  const createTimeInputRef = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useHotkeys<HTMLInputElement>(
      'enter',
      () => {
        newActivityRef.current?.focus();
      },
      { enableOnFormTags: ['input'] },
      [],
    );
  const startTimeRef = createTimeInputRef();
  const endTimeRef = createTimeInputRef();

  useEffect(() => {
    startTimeRef.current?.focus();
  }, [startTimeRef]);

  const update = (updateFn: (currentValue: LifeEntry) => void) => {
    updateFn(value);
    onChange(value);
  };

  return (
    <EditLifeEntryContainer>
      <EditTimeContainer>
        <TimeInput
          ref={startTimeRef}
          type="time"
          value={value.start_time}
          onChange={event =>
            update(lifeEntry => (lifeEntry.start_time = event.target.value))
          }
        />
        <img src={Arrow} alt="arrow" />
        <TimeInput
          ref={endTimeRef}
          type="time"
          value={value.end_time || ''}
          onChange={event =>
            update(lifeEntry => (lifeEntry.end_time = event.target.value))
          }
        />
      </EditTimeContainer>
      {value.life_entry_activities.map((lifeEntryActivity, index) => (
        <EditLifeEntryActivity
          key={lifeEntryActivity.id}
          value={lifeEntryActivity}
          onChange={newValue =>
            update(
              lifeEntry => (lifeEntry.life_entry_activities[index] = newValue),
            )
          }
          onDelete={() =>
            update(lifeEntry =>
              lifeEntry.life_entry_activities.splice(index, 1),
            )
          }
        />
      ))}
      <HR />
      <ActivitySelect
        apiClient={authContext.apiClient}
        ref={newActivityRef}
        tabIndex={0}
        placeholder="Add Activity"
        value={null}
        onChange={activity =>
          update(lifeEntry =>
            lifeEntry.life_entry_activities.push(
              createLifeEntryActivity(activity!),
            ),
          )
        }
      />
    </EditLifeEntryContainer>
  );
};
