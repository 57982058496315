import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LifeEntryActivity } from '../../../../models';
import {
  ActivityName,
  DescriptionContainer,
  LifeEntryActivityContainer,
  Quantity,
  Rating,
  TopContainer,
} from './styles';

interface Props {
  lifeEntryActivity: LifeEntryActivity;
}

export const DisplayLifeEntryActivity = ({ lifeEntryActivity }: Props) => {
  const showQuantity =
    lifeEntryActivity.activity!.activity_type!.show_quantity &&
    (lifeEntryActivity.quantity || 0) > 1;
  const showRating =
    lifeEntryActivity.activity!.activity_type!.show_rating &&
    lifeEntryActivity.rating;

  return (
    <LifeEntryActivityContainer>
      <TopContainer>
        <ActivityName>{lifeEntryActivity.activity!.name}</ActivityName>
        {showQuantity && <Quantity>{lifeEntryActivity.quantity}</Quantity>}
        {showRating && (
          <Rating>
            {lifeEntryActivity.rating}
            <FontAwesomeIcon icon={faStar} />
          </Rating>
        )}
      </TopContainer>
      {lifeEntryActivity.description && (
        <DescriptionContainer>
          {lifeEntryActivity.description}
        </DescriptionContainer>
      )}
    </LifeEntryActivityContainer>
  );
};
