import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { H1 } from '../../common/styles';
import {
  createActivity,
  createActivityType,
  isActivityTypeValid,
  isActivityValid,
  isReplaceActivityValid,
} from '../../common/utils';
import {
  CancelButton,
  CreateButton,
  DeleteButton,
  ReplaceButton,
  SaveButton,
} from '../../components/buttons';
import { EditActivity } from '../../components/life-history/editing/activity';
import { EditActivityType } from '../../components/life-history/editing/activity-type';
import { EditReplaceActivity } from '../../components/life-history/editing/replace-activity';
import { Activity, ActivityType, ReplaceActivity } from '../../models';
import { ButtonsGroup, EditingPanel } from '../styles';
import { ActivityEditMode, EditActivityItem, ListActivityItem } from './index';

interface Props {
  activityItem?: EditActivityItem;
  activityEditMode: ActivityEditMode;

  onCreate: (activityItem: ListActivityItem) => void;
  onUpdate: (activityItem: ListActivityItem) => void;
  onReplace: (activityItem: ReplaceActivity) => void;
  onDelete: () => void;
  onCancel: () => void;
}

const canCreate = (activityItem?: EditActivityItem) => !activityItem;
const canDelete = (
  mode: ActivityEditMode,
  isCreate: boolean,
  activityItem?: EditActivityItem,
) => !!activityItem && !isCreate && mode !== ActivityEditMode.REPLACE_ACTIVITY;
const canCancel = (activityItem?: EditActivityItem) => !!activityItem;
const canReplace = (
  mode: ActivityEditMode,
  isCreate: boolean,
  activityItem?: EditActivityItem,
) =>
  canDelete(mode, isCreate, activityItem) &&
  mode === ActivityEditMode.ACTIVITIES;
const canSave = (mode: ActivityEditMode, activityItem?: EditActivityItem) => {
  if (!activityItem) return false;
  switch (mode) {
    case ActivityEditMode.TYPES:
      return isActivityTypeValid(activityItem as ActivityType);
    case ActivityEditMode.ACTIVITIES:
      return isActivityValid(activityItem as Activity);
    case ActivityEditMode.REPLACE_ACTIVITY:
      return isReplaceActivityValid(activityItem as ReplaceActivity);
  }
};

export const EditPanel = ({
  activityItem,
  activityEditMode,
  onCreate,
  onUpdate,
  onReplace,
  onDelete,
  onCancel,
}: Props) => {
  const [editingMode, setEditingMode] =
    useState<ActivityEditMode>(activityEditMode);
  const [editingItem, setEditingItem] = useState<
    EditActivityItem | undefined
  >();
  const [isCreate, setIsCreate] = useState<boolean>(false);

  useEffect(() => {
    setIsCreate(false);
    setEditingMode(activityEditMode);
    setEditingItem(activityItem ? cloneDeep(activityItem) : undefined);
  }, [activityEditMode, activityItem]);

  useHotkeys(
    'enter',
    () => {
      if (canCreate(editingItem)) addItem();
    },
    {},
    [editingItem],
  );

  useHotkeys(
    'shift+enter',
    () => {
      if (canSave(editingMode, editingItem)) saveItem();
    },
    { enableOnFormTags: true },
    [editingMode, editingItem],
  );

  useHotkeys(
    'escape',
    () => {
      if (canCancel(editingItem)) cancel();
    },
    { enableOnFormTags: true },
    [editingItem],
  );

  useHotkeys(
    'shift+delete',
    () => {
      if (canDelete(editingMode, isCreate, editingItem)) deleteItem();
    },
    { enableOnFormTags: true },
    [editingMode, editingItem, isCreate],
  );

  useHotkeys(
    'alt+r',
    () => {
      if (canReplace(editingMode, isCreate, editingItem)) startReplaceItem();
    },
    { enableOnFormTags: true },
    [editingMode, editingItem, isCreate],
  );

  const addItem = () => {
    setIsCreate(true);
    setEditingItem(
      activityEditMode === ActivityEditMode.TYPES
        ? createActivityType()
        : createActivity(),
    );
  };
  const saveItem = () => {
    if (editingMode === ActivityEditMode.REPLACE_ACTIVITY) replaceItem();
    else if (isCreate) {
      onCreate(editingItem! as ListActivityItem);
    } else {
      onUpdate(editingItem! as ListActivityItem);
    }
    setEditingItem(undefined);
  };
  const startReplaceItem = () => {
    const replaceActivity: ReplaceActivity = {
      activities: [],
      description: '',
      originalActivity: editingItem as Activity,
    };
    setEditingItem(replaceActivity);
    setEditingMode(ActivityEditMode.REPLACE_ACTIVITY);
  };
  const cancel = () => {
    setEditingItem(undefined);
    onCancel();
  };
  const deleteItem = () => {
    onDelete();
    setEditingItem(undefined);
  };
  const replaceItem = () => {
    onReplace(editingItem! as ReplaceActivity);
    setEditingItem(undefined);
  };

  return (
    <>
      <EditingPanel>
        <H1>Editing</H1>
        <ButtonsGroup>
          {canReplace(editingMode, isCreate, editingItem) && (
            <ReplaceButton onClick={startReplaceItem} />
          )}
          {canCreate(editingItem) && <CreateButton onClick={addItem} />}
          {canSave(editingMode, editingItem) && (
            <SaveButton onClick={saveItem} />
          )}
          {canCancel(editingItem) && <CancelButton onClick={cancel} />}
          {canDelete(editingMode, isCreate, editingItem) && (
            <DeleteButton onClick={deleteItem} />
          )}
        </ButtonsGroup>
      </EditingPanel>
      {editingItem && editingMode === ActivityEditMode.TYPES && (
        <EditActivityType
          value={editingItem as ActivityType}
          onChange={value => setEditingItem({ ...value })}
        />
      )}
      {editingItem && editingMode === ActivityEditMode.ACTIVITIES && (
        <EditActivity
          value={editingItem as Activity}
          onChange={value => setEditingItem({ ...value })}
        />
      )}
      {editingItem && editingMode === ActivityEditMode.REPLACE_ACTIVITY && (
        <EditReplaceActivity
          value={editingItem as ReplaceActivity}
          onChange={value => setEditingItem({ ...value })}
        />
      )}
    </>
  );
};
