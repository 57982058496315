import Arrow from '../../../../assets/arrow.svg';
import { LifeEntry } from '../../../../models';
import { DisplayLifeEntryActivity } from '../life-entry-activity';
import {
  EntriesContainer,
  LifeEntryContainer,
  TimeContainer,
  TimeEnd,
  TimeStart,
  TimeText,
  VerticalLine,
} from './styles';

interface Props {
  value: LifeEntry;
  isSelected?: boolean;
  onSelect?: (lifeEntry: LifeEntry) => void;
}

export const DisplayLifeEntry = ({ value, isSelected, onSelect }: Props) => (
  <LifeEntryContainer>
    <TimeContainer>
      <TimeStart>
        <TimeText>{value.start_time!.substring(0, 5)}</TimeText>
        <VerticalLine />
      </TimeStart>
      {value.end_time && (
        <TimeEnd>
          <img src={Arrow} alt="arrow" />
          <TimeText>{value.end_time.substring(0, 5)}</TimeText>
        </TimeEnd>
      )}
    </TimeContainer>
    <EntriesContainer
      isSelected={isSelected}
      onClick={() => {
        if (onSelect) onSelect(value);
      }}
    >
      {value.life_entry_activities.map(lifeEntryActivity => (
        <DisplayLifeEntryActivity
          key={lifeEntryActivity.id!}
          lifeEntryActivity={lifeEntryActivity}
        />
      ))}
    </EntriesContainer>
  </LifeEntryContainer>
);
