import styled from 'styled-components';
import {
  BaseContainer,
  GAP_MEDIUM,
  GAP_SMALL,
  SELECTED_OUTLINE,
} from '../../../../common/styles';

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_MEDIUM};
  flex: 1;
  min-width: min(100%, 600px);
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const LifeEntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SMALL};
`;

export interface NoteContainerProps {
  isSelected?: boolean;
}

export const NoteContainer = styled(BaseContainer)`
  white-space: pre-line;

  ${(props: NoteContainerProps) => props.isSelected && SELECTED_OUTLINE}
`;
