import dateFormat from 'dateformat';
import { isEqual, omit } from 'lodash';
import {
  Activity,
  ActivityType,
  LifeEntry,
  LifeEntryActivity,
  ReplaceActivity,
} from '../models';

export const randomNumber = (length: number = 12) =>
  Math.floor(Math.random() * Math.pow(10, length));

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const isEqualOmit = <T extends object>(
  a: T,
  b: T,
  paths: Parameters<typeof omit>[1],
) => {
  return isEqual(omit(a, paths), omit(b, paths));
};

export const createLifeEntry = (): LifeEntry => ({
  id: randomNumber(), // Temporary random id, real id will be generated server side
  start_time: dateFormat(new Date(), 'HH:MM:00'),
  life_entry_activities: [],
});

export const createActivityType = (): ActivityType => ({
  id: randomNumber(), // Temporary random id, real id will be generated server side
  name: '',
  show_rating: false,
  show_quantity: false,
});

export const createActivity = (): Activity => ({
  id: randomNumber(), // Temporary random id, real id will be generated server side
  name: '',
});

export const createLifeEntryActivity = (
  activity: Activity,
): LifeEntryActivity => {
  const lifeEntryActivity: LifeEntryActivity = {
    id: randomNumber(), // Temporary random id, real id will be generated server side
  };
  fillLifeEntryActivity(lifeEntryActivity, activity);
  return lifeEntryActivity;
};

export const fillLifeEntryActivity = (
  lifeEntryActivity: LifeEntryActivity,
  activity: Activity,
) => {
  lifeEntryActivity.activity = activity!;
  lifeEntryActivity.activity_id = activity!.id;
  if (activity.activity_type?.show_quantity) lifeEntryActivity.quantity = 1;
  if (activity.activity_type?.show_rating) lifeEntryActivity.rating = 5;
};

export const isLifeEntryValid = (lifeEntry: LifeEntry): boolean => {
  return !!lifeEntry.start_time;
};

export const isActivityTypeValid = (activityType: ActivityType): boolean => {
  return !!activityType.name;
};

export const isActivityValid = (activity: Activity): boolean => {
  return !!activity.name && !!activity.activity_type_id;
};

export const isReplaceActivityValid = (
  replaceActivity: ReplaceActivity,
): boolean => {
  return (
    !!replaceActivity.originalActivity && replaceActivity.activities.length > 0
  );
};

export interface EnumOption<T> {
  key: string;
  value: T;
}

export const generateEnumOptions = <T>(enumType: Record<string, T>) => {
  return Object.entries(enumType).map(([key, value]) => ({
    key: key,
    value: value,
  })) as EnumOption<T>[];
};
