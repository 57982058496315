import axios, { AxiosBasicCredentials, Method } from 'axios';
import {
  Activity,
  ActivityType,
  AuthResult,
  BestOfQuery,
  BestOfResult,
  Day,
  LifeEntry,
  LifeEntryActivity,
  ReplaceActivityPayload,
  SearchQuery,
  SearchResult,
} from '../models';

const SERVER_URL = 'https://lifehistory.ca';

export const ENDPOINTS = {
  AUTHENTICATE: '/api/authenticate',
  ACTIVITY_TYPES: `/api/activity_types`,
  ACTIVITIES: `/api/activities`,
  DAYS: `/api/days`,
  LIFE_ENTRIES: `/api/life_entries`,
  LIFE_ENTRY_ACTIVITIES: `/api/life_entry_activities`,
};

export interface ApiClient {
  // Auth
  authenticate: () => Promise<AuthResult>;
  // Days
  getDay: (dateString: string) => Promise<Day>;
  getDayById: (id: number) => Promise<Day>;
  addDay: (day: Day) => Promise<Day>;
  updateDay: (day: Day) => Promise<Day>;
  // Activity Types
  getActivityTypes: () => Promise<ActivityType[]>;
  getActivityType: (id: number) => Promise<ActivityType>;
  addActivityType: (activityType: ActivityType) => Promise<ActivityType>;
  updateActivityType: (activityType: ActivityType) => Promise<ActivityType>;
  deleteActivityType: (activityType: ActivityType) => Promise<ActivityType>;
  searchActivityTypes: (term: string) => Promise<ActivityType[]>;
  // Activities
  getActivities: () => Promise<Activity[]>;
  getActivity: (id: number) => Promise<Activity>;
  addActivity: (activity: Activity) => Promise<Activity>;
  updateActivity: (activity: Activity) => Promise<Activity>;
  deleteActivity: (activity: Activity) => Promise<Activity>;
  replaceActivity: (
    activity: Activity,
    replaceActivityPayload: ReplaceActivityPayload,
  ) => Promise<void>;
  searchActivities: (term: string) => Promise<Activity[]>;
  getActivityDescriptions: (
    activity: Activity,
    filter: string,
  ) => Promise<string[]>;
  getActivitiesBestOf: (query: BestOfQuery) => Promise<BestOfResult[]>;
  // Life Entries
  addLifeEntry: (lifeEntry: LifeEntry) => Promise<LifeEntry>;
  getLifeEntry: (id: number) => Promise<LifeEntry>;
  updateLifeEntry: (lifeEntry: LifeEntry) => Promise<LifeEntry>;
  deleteLifeEntry: (lifeEntry: LifeEntry) => Promise<LifeEntry>;
  // Life Entry Activities
  addLifeEntryActivity: (
    lifeEntryActivity: LifeEntryActivity,
  ) => Promise<LifeEntryActivity>;
  getLifeEntryActivity: (id: number) => Promise<LifeEntryActivity>;
  updateLifeEntryActivity: (
    lifeEntryActivity: LifeEntryActivity,
  ) => Promise<LifeEntryActivity>;
  deleteLifeEntryActivity: (
    lifeEntryActivity: LifeEntryActivity,
  ) => Promise<LifeEntryActivity>;
  // Search
  search: (query: SearchQuery) => Promise<SearchResult[]>;
}

const apiCall = async <T>(
  method: Method,
  path: string,
  data?: any,
  credentials?: AxiosBasicCredentials,
) => {
  const url = new URL(path, SERVER_URL);
  const axiosResponse = await axios.request<T>({
    method,
    url: url.href,
    data,
    auth: credentials,
  });
  return axiosResponse.data;
};

export const buildApiClient = (
  credentials: AxiosBasicCredentials,
): ApiClient => {
  return {
    // Auth
    authenticate: () =>
      apiCall<AuthResult>('POST', ENDPOINTS.AUTHENTICATE, credentials),
    // Days
    getDay: (dateString: string) =>
      apiCall<Day>('GET', `${ENDPOINTS.DAYS}/${dateString}`, {}, credentials),
    getDayById: (id: number) =>
      apiCall<Day>('GET', `${ENDPOINTS.DAYS}/${id}`, {}, credentials),
    addDay: (day: Day) =>
      apiCall<Day>('POST', ENDPOINTS.DAYS, day, credentials),
    updateDay: (day: Day) =>
      apiCall<Day>('PUT', `${ENDPOINTS.DAYS}/${day.id}`, day, credentials),
    // Activity Types
    getActivityTypes: () =>
      apiCall<ActivityType[]>('GET', ENDPOINTS.ACTIVITY_TYPES, {}, credentials),
    getActivityType: (id: number) =>
      apiCall<ActivityType>(
        'GET',
        `${ENDPOINTS.ACTIVITY_TYPES}/${id}`,
        {},
        credentials,
      ),
    addActivityType: (activityType: ActivityType) =>
      apiCall<ActivityType>(
        'POST',
        ENDPOINTS.ACTIVITY_TYPES,
        activityType,
        credentials,
      ),
    updateActivityType: (activityType: ActivityType) =>
      apiCall<ActivityType>(
        'PUT',
        `${ENDPOINTS.ACTIVITY_TYPES}/${activityType.id}`,
        activityType,
        credentials,
      ),
    deleteActivityType: (activityType: ActivityType) =>
      apiCall<ActivityType>(
        'DELETE',
        `${ENDPOINTS.ACTIVITY_TYPES}/${activityType.id}`,
        {},
        credentials,
      ),
    searchActivityTypes: (term: string) =>
      apiCall<ActivityType[]>(
        'GET',
        `${ENDPOINTS.ACTIVITY_TYPES}/search/${term}`,
        {},
        credentials,
      ),
    // Activities
    getActivities: () =>
      apiCall<Activity[]>('GET', ENDPOINTS.ACTIVITIES, {}, credentials),
    getActivity: (id: number) =>
      apiCall<Activity>(
        'GET',
        `${ENDPOINTS.ACTIVITIES}/${id}`,
        {},
        credentials,
      ),
    addActivity: (activity: Activity) =>
      apiCall<Activity>('POST', ENDPOINTS.ACTIVITIES, activity, credentials),
    updateActivity: (activity: Activity) =>
      apiCall<Activity>(
        'PUT',
        `${ENDPOINTS.ACTIVITIES}/${activity.id}`,
        activity,
        credentials,
      ),
    deleteActivity: (activity: Activity) =>
      apiCall<Activity>(
        'DELETE',
        `${ENDPOINTS.ACTIVITIES}/${activity.id}`,
        {},
        credentials,
      ),
    replaceActivity: (
      activity: Activity,
      replaceActivityPayload: ReplaceActivityPayload,
    ) =>
      apiCall<void>(
        'POST',
        `${ENDPOINTS.ACTIVITIES}/${activity.id}/replace`,
        replaceActivityPayload,
        credentials,
      ),
    searchActivities: (term: string) =>
      apiCall<Activity[]>(
        'GET',
        `${ENDPOINTS.ACTIVITIES}/search/${term}`,
        {},
        credentials,
      ),
    getActivityDescriptions: (activity: Activity, filter: string) =>
      apiCall<string[]>(
        'GET',
        `${ENDPOINTS.ACTIVITIES}/${activity.id}/descriptions?filter=${filter}`,
        {},
        credentials,
      ),
    getActivitiesBestOf: (query: BestOfQuery) =>
      apiCall<BestOfResult[]>(
        'POST',
        `${ENDPOINTS.ACTIVITIES}/best_of`,
        query,
        credentials,
      ),
    // Life Entries
    addLifeEntry: (lifeEntry: LifeEntry) =>
      apiCall<LifeEntry>(
        'POST',
        ENDPOINTS.LIFE_ENTRIES,
        lifeEntry,
        credentials,
      ),
    getLifeEntry: (id: number) =>
      apiCall<LifeEntry>(
        'GET',
        `${ENDPOINTS.LIFE_ENTRIES}/${id}`,
        {},
        credentials,
      ),
    updateLifeEntry: (lifeEntry: LifeEntry) =>
      apiCall<LifeEntry>(
        'PUT',
        `${ENDPOINTS.LIFE_ENTRIES}/${lifeEntry.id}`,
        lifeEntry,
        credentials,
      ),
    deleteLifeEntry: (lifeEntry: LifeEntry) =>
      apiCall<LifeEntry>(
        'DELETE',
        `${ENDPOINTS.LIFE_ENTRIES}/${lifeEntry.id}`,
        {},
        credentials,
      ),
    // Life Entry Activities
    addLifeEntryActivity: (lifeEntryActivity: LifeEntryActivity) =>
      apiCall<LifeEntryActivity>(
        'POST',
        ENDPOINTS.LIFE_ENTRY_ACTIVITIES,
        lifeEntryActivity,
        credentials,
      ),
    getLifeEntryActivity: (id: number) =>
      apiCall<LifeEntryActivity>(
        'GET',
        `${ENDPOINTS.LIFE_ENTRY_ACTIVITIES}/${id}`,
        {},
        credentials,
      ),
    updateLifeEntryActivity: (lifeEntryActivity: LifeEntryActivity) =>
      apiCall<LifeEntryActivity>(
        'PUT',
        `${ENDPOINTS.LIFE_ENTRY_ACTIVITIES}/${lifeEntryActivity.id}`,
        lifeEntryActivity,
        credentials,
      ),
    deleteLifeEntryActivity: (lifeEntryActivity: LifeEntryActivity) =>
      apiCall<LifeEntryActivity>(
        'DELETE',
        `${ENDPOINTS.LIFE_ENTRY_ACTIVITIES}/${lifeEntryActivity.id}`,
        {},
        credentials,
      ),
    // Search
    search: (query: SearchQuery) =>
      apiCall<SearchResult[]>(
        'POST',
        `${ENDPOINTS.LIFE_ENTRIES}/search`,
        query,
        credentials,
      ),
  };
};
