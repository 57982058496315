import dateFormat from 'dateformat';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import { H1, H2 } from '../../../../common/styles';
import { DayItem, EditingModes, getEditMode } from '../../../../common/types';
import { Day } from '../../../../models';
import { parseDate } from '../../../../network/utils';
import { Spinner } from '../../../spinner';
import { DisplayLifeEntry } from '../life-entry';
import {
  DayContainer,
  LifeEntriesContainer,
  NoteContainer,
  TitleContainer,
} from './styles';

interface Props {
  day: Day;
  pendingRequest: boolean;
  selectedItem?: DayItem;
  onSelectionChange?: (item: DayItem) => void;
}

export const DisplayDay = ({
  day,
  pendingRequest,
  selectedItem,
  onSelectionChange,
}: Props) => {
  const date = parseDate(day.date!);

  const onSelectItem = useCallback(
    (item: DayItem) => {
      if (onSelectionChange) onSelectionChange(item);
    },
    [onSelectionChange],
  );

  return (
    <DayContainer>
      <TitleContainer>
        <div>
          <H1>{dateFormat(date, 'dddd')}</H1>
          <H2>{dateFormat(date, 'd mmmm yyyy')}</H2>
        </div>
        {pendingRequest && <Spinner />}
      </TitleContainer>
      <LifeEntriesContainer>
        {sortBy(day.life_entries, lifeEntry => lifeEntry.start_time).map(
          lifeEntry => (
            <DisplayLifeEntry
              key={lifeEntry.id!}
              value={lifeEntry}
              isSelected={lifeEntry === selectedItem}
              onSelect={onSelectItem}
            />
          ),
        )}
      </LifeEntriesContainer>
      {day.note && (
        <NoteContainer
          onClick={() => onSelectItem(day.note || '')}
          isSelected={getEditMode(selectedItem) === EditingModes.NOTE}
        >
          {day.note}
        </NoteContainer>
      )}
    </DayContainer>
  );
};
