import React, { useEffect, useRef } from 'react';
import { Input } from '../../../../common/styles';
import { ActivityType } from '../../../../models';
import { CustomSelect } from '../../../custom-select';
import {
  EditActivityTypeContainer,
  InputsRowContainer,
  Label,
  OptionContainer,
} from './styles';

interface Props {
  value: ActivityType;
  onChange: (value: ActivityType) => void;
}

interface BooleanOption {
  display: string;
  value: boolean;
}

const BOOLEAN_OPTIONS: BooleanOption[] = [
  {display: 'Yes', value: true},
  {display: 'No', value: false},
];

const getOptionLabel = (option: BooleanOption, prefix: string) => (
  <OptionContainer>
    <Label>{prefix}</Label>
    {option.display}
  </OptionContainer>
);

export const EditActivityType = ({value, onChange}: Props) => {
  const nameRef = useRef<HTMLInputElement>(null);

  const update = (updateFn: (currentValue: ActivityType) => void) => {
    updateFn(value);
    onChange(value);
  };

  useEffect(() => {
    nameRef.current?.focus();
  }, [nameRef]);

  return (
    <EditActivityTypeContainer>
      <Input
        ref={nameRef}
        type="text"
        placeholder="Name"
        value={value.name}
        onChange={event =>
          update(activityType => (activityType.name = event.target.value))
        }
      />
      <InputsRowContainer>
        <CustomSelect<BooleanOption>
          value={BOOLEAN_OPTIONS.find(o => o.value === value.show_quantity)}
          getOptionLabel={o =>
            getOptionLabel(o, 'Quantity') as unknown as string
          }
          options={BOOLEAN_OPTIONS}
          onChange={option =>
            update(activityType => (activityType.show_quantity = option!.value))
          }
        />
        <CustomSelect<BooleanOption>
          value={BOOLEAN_OPTIONS.find(o => o.value === value.show_rating)}
          getOptionLabel={o => getOptionLabel(o, 'Rating') as unknown as string}
          options={BOOLEAN_OPTIONS}
          onChange={option =>
            update(activityType => (activityType.show_rating = option!.value))
          }
        />
      </InputsRowContainer>
    </EditActivityTypeContainer>
  );
};
