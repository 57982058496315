import { faListOl, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef } from 'react';
import { SelectInstance } from 'react-select';
import { RedIconButton } from '../../../../common/styles';
import { fillLifeEntryActivity } from '../../../../common/utils';
import { AuthenticatedContext } from '../../../../hooks/authenticated-context';
import { LifeEntryActivity } from '../../../../models';
import { ActivitySelect } from '../../../activity-select';
import { WrappedDescription } from '../../../description-select';
import {
  ActivityContainer,
  ColoredDescriptionSelect,
  EditLifeEntryActivityContainer,
  LineContainer,
  NumberInputContainer,
  QuantityInputIcon,
  QuantityRatingInput,
  RatingInputIcon,
} from './styles';

interface Props {
  value: LifeEntryActivity;
  onChange: (value: LifeEntryActivity) => void;
  onDelete: () => void;
}

export const EditLifeEntryActivity = ({ value, onChange, onDelete }: Props) => {
  const authContext = useContext(AuthenticatedContext);
  const descriptionRef = useRef<SelectInstance<WrappedDescription>>(null);

  useEffect(() => {
    descriptionRef.current?.focus();
  }, []);

  const update = (updateFn: (currentValue: LifeEntryActivity) => void) => {
    updateFn(value);
    onChange(value);
  };

  const showQuantity = value.activity?.activity_type?.show_quantity;
  const showRating = value.activity?.activity_type?.show_rating;

  return (
    <EditLifeEntryActivityContainer>
      <LineContainer>
        <ActivityContainer>
          <ActivitySelect
            apiClient={authContext.apiClient}
            value={value.activity}
            onChange={activity =>
              update(lifeEntryActivity => {
                fillLifeEntryActivity(lifeEntryActivity, activity!);
              })
            }
          />
        </ActivityContainer>
        <RedIconButton tabIndex={-1} onClick={onDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </RedIconButton>
      </LineContainer>
      {value.activity && (
        <ColoredDescriptionSelect
          ref={descriptionRef}
          apiClient={authContext.apiClient}
          activity={value.activity}
          value={value.description ? { value: value.description } : undefined}
          onChange={wrappedOption =>
            update(
              lifeEntryActivity =>
                (lifeEntryActivity.description = wrappedOption?.value),
            )
          }
        />
      )}
      {(showQuantity || showRating) && (
        <LineContainer>
          {showQuantity && (
            <NumberInputContainer>
              <QuantityRatingInput
                type="number"
                value={value.quantity}
                min={0}
                onChange={event =>
                  update(
                    lifeEntryActivity =>
                      (lifeEntryActivity.quantity = Number(event.target.value)),
                  )
                }
              />
              <QuantityInputIcon>
                <FontAwesomeIcon icon={faListOl} />
              </QuantityInputIcon>
            </NumberInputContainer>
          )}
          {showRating && (
            <NumberInputContainer>
              <QuantityRatingInput
                type="number"
                value={value.rating}
                max="10"
                min="0"
                onChange={event =>
                  update(
                    lifeEntryActivity =>
                      (lifeEntryActivity.rating = Number(event.target.value)),
                  )
                }
              />
              <RatingInputIcon>
                <FontAwesomeIcon icon={faStar} />
              </RatingInputIcon>
            </NumberInputContainer>
          )}
        </LineContainer>
      )}
    </EditLifeEntryActivityContainer>
  );
};
