import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LhLogo from '../../assets/lh_logo_vector.svg';
import { DateSelect } from '../../components/date-select';
import { Spinner } from '../../components/spinner';
import { useApiCall } from '../../hooks/api-call-wrapper';
import { useQuery } from '../../hooks/use-query';
import { Day } from '../../models';
import { getDay } from '../../network/operations';
import { parseDate } from '../../network/utils';
import { PageContent, PageHeader } from '../styles';
import { CreateDay } from './create-day';
import { DayContent } from './day-content';
import { Logo, SpinnerContainer } from './styles';

export const CalendarPage = () => {
  const { pendingRequest, apiCall } = useApiCall();

  const searchParams = useQuery();
  const requestedDate = useMemo(() => {
    const dateQuery = searchParams.get('date');
    if (dateQuery) {
      const parsedDate = parseDate(dateQuery);
      if (!isNaN(parsedDate.getTime())) return parsedDate;
    }
  }, [searchParams]);

  const [day, setDay] = useState<Day>();
  const [date, setDate] = useState<Date>(
    requestedDate ? requestedDate : new Date(),
  );
  useEffect(() => {
    const fetchDay = async () => {
      const getDayResult = await apiCall(apiClient => getDay(apiClient, date));
      setDay(getDayResult);
    };

    fetchDay();
  }, [apiCall, date]);

  const onDateChange = useCallback((newDate: Date) => {
    setDate(newDate);
  }, []);

  const onDayCreated = useCallback((newDay: Day) => {
    setDay(newDay);
  }, []);

  return (
    <div>
      <PageHeader>
        <Logo src={LhLogo} alt="logo" />
        <DateSelect value={date} onChange={onDateChange} />
      </PageHeader>
      <PageContent>
        {pendingRequest ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : day ? (
          <DayContent selectedDay={day} />
        ) : (
          <CreateDay date={date} onDayCreated={onDayCreated} />
        )}
      </PageContent>
    </div>
  );
};
