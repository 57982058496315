import styled from 'styled-components';
import { COLORS } from '../../common/colors';
import { GAP_LARGE, GAP_MEDIUM } from '../../common/styles';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10vh;
  width: calc(min(100%, 400px));
`;

export const LoginBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_LARGE};

  padding: 15px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_MEDIUM};
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${COLORS.RED};
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 380px;
  height: auto;
`;
