export interface AuthResult {
  authenticate_result: number;
}

export interface ActivityType {
  id: number;
  created_date?: Date;
  name: string;
  show_quantity: boolean;
  show_rating: boolean;
}

export interface Activity {
  id: number;
  created_date?: Date;
  name: string;
  activity_type_id?: number;
  activity_type?: ActivityType;
}

export interface Day {
  id?: number;
  created_date?: Date;
  date?: string; // YYYY-MM-DD
  note?: string;
  life_entries: LifeEntry[];
}

export interface LifeEntryActivity {
  id: number;
  created_date?: Date;
  life_entry_id?: number;
  activity_id?: number;
  description?: string;
  quantity?: number;
  rating?: number;
  activity?: Activity;
}

export interface LifeEntry {
  id: number;
  created_date?: Date;
  day_id?: number;
  start_time?: string;
  end_time?: string | null;
  life_entry_activities: LifeEntryActivity[];
}

export interface SearchQuery {
  start_date?: string;
  end_date?: string;
  activity_type_id?: number;
  activity_id?: number;
  text?: string;
}

export interface SearchResult {
  day_id: number;
  date: string;
  start_time: string;
  end_time: string;
  description: string;
  quantity: number;
  rating: number;
  activity_type_name: string;
  activity_name: string;
}

export interface ReplaceActivity {
  originalActivity: Activity;
  activities: Activity[];
  description: string;
}

export interface ReplaceActivityPayload {
  activity_ids: number[];
  description: string;
}

export enum BestOfTimeGroup {
  All_Time = 'all_time',
  Year = 'year',
  Month = 'month',
}

export enum BestOfTextGroup {
  Name = 'name',
  Description = 'description',
  NameAndDescription = 'name_description',
}

export enum BestOfType {
  Rating = 'rating',
  Count = 'count',
}

export interface BestOfQuery {
  activity_type_id?: number;
  activity_id?: number;
  time_group: BestOfTimeGroup;
  text_group: BestOfTextGroup;
  best_of: BestOfType;
  start_date?: string;
  end_date?: string;
}

export interface BestOfResult {
  best_of_value: number;
  name: string;
  time_group: string;
}
