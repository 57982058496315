import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Props as SelectProps, SelectInstance } from 'react-select';
import { Activity } from '../models';
import { ApiClient } from '../network/api-client';
import { CustomAsyncCreatableSelect } from './custom-select';

interface Props extends SelectProps<WrappedDescription, false> {
  apiClient: ApiClient;
  activity: Activity;
}

export interface WrappedDescription {
  value: string;
}

export const DescriptionSelect = forwardRef<
  SelectInstance<WrappedDescription>,
  Props
>((props, ref) => {
  const [defaultOptions, setDefaultOptions] = useState<WrappedDescription[]>(
    [],
  );

  const searchDescriptions = useCallback(
    async (input: string): Promise<WrappedDescription[]> => {
      const descriptions = await props.apiClient.getActivityDescriptions(
        props.activity,
        input,
      );
      return descriptions.map(d => ({ value: d }));
    },
    [props.apiClient, props.activity],
  );

  useEffect(() => {
    (async () => {
      const options = await searchDescriptions('');
      setDefaultOptions(options);
    })();
  }, [props.activity, searchDescriptions]);

  return (
    <CustomAsyncCreatableSelect<WrappedDescription>
      ref={ref}
      backspaceRemovesValue
      isClearable
      placeholder="Description"
      defaultOptions={defaultOptions}
      getOptionLabel={a => a.value}
      getOptionValue={a => a.value}
      loadOptions={searchDescriptions}
      {...props}
    />
  );
});
