import { BestOfResult } from '../../models';
import {
  BestOfGroupContainer,
  BestOfGroupHeader,
  BestOfGroupItem,
  BestOfGroupItemContainer,
  BestOfGroupItemName,
  BestOfGroupItemRank,
  BestOfGroupItemValue,
} from './styles';

interface Props {
  title: string;
  bestOfResults: BestOfResult[];
}

export const BestOfGroup = ({ title, bestOfResults }: Props) => {
  const renderItem = (index: number, result: BestOfResult) => {
    return (
      <BestOfGroupItem key={`best-of-item-${index}`}>
        <BestOfGroupItemRank>{index}.</BestOfGroupItemRank>
        <BestOfGroupItemName>{result.name}</BestOfGroupItemName>
        <BestOfGroupItemValue>
          {Math.round(result.best_of_value * 100) / 100}
        </BestOfGroupItemValue>
      </BestOfGroupItem>
    );
  };

  return (
    <BestOfGroupContainer>
      <BestOfGroupHeader>{title}</BestOfGroupHeader>
      <BestOfGroupItemContainer>
        {bestOfResults.map((result, index) => renderItem(index + 1, result))}
      </BestOfGroupItemContainer>
    </BestOfGroupContainer>
  );
};
