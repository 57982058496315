import React from 'react';
import { ApiClient } from '../network/api-client';

interface AuthContext {
  apiClient: ApiClient;
}

export const AuthenticatedContext = React.createContext<AuthContext>({
  apiClient: {} as any,
});
