export const memoryStorage = () => {
  const data = new Map<string, string>();
  return {
    getItem(key: string): string | undefined {
      return data.get(key);
    },
    setItem(key: string, value: string) {
      data.set(key, value);
    },
    removeItem(key: string) {
      data.delete(key);
    },
    key(_: number): string | null {
      throw new Error('Not implemented');
    },
    clear() {
      data.clear();
    },
    length: data.size,
  } as Storage;
};
