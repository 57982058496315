import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Input } from '../common/styles';

export const StyledDateInput = styled(Input)`
  // To fix chrome mobile display
  width: auto;
  -webkit-appearance: none;
`;

export const DateInput = (props: InputHTMLAttributes<HTMLInputElement>) => (
  <StyledDateInput
    type="text"
    onFocus={event => (event.target.type = 'date')}
    onBlur={event => (event.target.type = 'text')}
    {...props}
  />
);
