import { AxiosBasicCredentials } from 'axios';
import { memoryStorage } from './memory-storage';

let memory: Storage;
export const getStorage = (): Storage => {
  try {
    return localStorage;
  } catch (e) {
    if (!memory) memory = memoryStorage();
    return memory;
  }
};

const CREDENTIALS_KEY = 'life_history.credentials';

export const getStoredCredentials = (): AxiosBasicCredentials | undefined => {
  const storage = getStorage();
  const storedString = storage.getItem(CREDENTIALS_KEY);

  if (storedString) {
    const decoded = atob(storedString);
    return JSON.parse(decoded) as AxiosBasicCredentials;
  }
};

export const storeCredentials = (credentials: AxiosBasicCredentials) => {
  const storage = getStorage();
  const stringToStore = JSON.stringify(credentials);
  const encoded = btoa(stringToStore);
  storage.setItem(CREDENTIALS_KEY, encoded);
};

export const clearCredentials = () => {
  const storage = getStorage();
  storage.removeItem(CREDENTIALS_KEY);
};
