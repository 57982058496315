import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dateFormat from 'dateformat';
import React, { useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router';
import { H1, H2, IconButton } from '../../common/styles';
import { AuthenticatedContext } from '../../hooks/authenticated-context';
import { Day } from '../../models';
import { formatDate } from '../../network/utils';
import { CreateDayContainer } from './styles';

interface Props {
  date: Date;
  onDayCreated: (day: Day) => void;
}

export const CreateDay = ({ date, onDayCreated }: Props) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthenticatedContext);

  const createDay = async () => {
    try {
      const newDay = await authContext.apiClient.addDay({
        date: formatDate(date),
        life_entries: [],
      });
      onDayCreated(newDay);
    } catch (e) {
      navigate('/error');
      throw e;
    }
  };
  useHotkeys('enter', createDay, {}, []);

  return (
    <CreateDayContainer>
      <div>
        <H1>Create day</H1>
        <H2>{dateFormat(date, 'd mmmm yyyy')}</H2>
      </div>
      <IconButton onClick={createDay}>
        <FontAwesomeIcon icon={faPlus} />
      </IconButton>
    </CreateDayContainer>
  );
};
