import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { H2 } from '../../common/styles';
import { EnumOption, generateEnumOptions } from '../../common/utils';
import { ActivitySelect } from '../../components/activity-select';
import { ActivityTypeSelect } from '../../components/activity-type-select';
import { BestOfGroup } from '../../components/best-of-group';
import { CustomSelect } from '../../components/custom-select';
import { DateInput } from '../../components/date-input';
import { Spinner } from '../../components/spinner';
import { useApiCall } from '../../hooks/api-call-wrapper';
import {
  Activity,
  ActivityType,
  BestOfResult,
  BestOfTextGroup,
  BestOfTimeGroup,
  BestOfType,
} from '../../models';
import {
  HalfWidthContainer,
  PageContent,
  PageHeader,
  WrappingInputsContainer,
} from '../styles';
import { BestOfButton, BestOfContainer, MaxPerGroupInput } from './styles';
import { getGroupTitle, groupResults } from './utils';

const timeGroups = generateEnumOptions(BestOfTimeGroup);
const textGroups = generateEnumOptions(BestOfTextGroup);
const bestOfTypes = generateEnumOptions(BestOfType);

export const DisplayBestOfTimeGroup: Record<BestOfTimeGroup, string> = {
  [BestOfTimeGroup.All_Time]: 'All',
  [BestOfTimeGroup.Year]: 'Year',
  [BestOfTimeGroup.Month]: 'Month',
};

export const DisplayBestOfTextGroup: Record<BestOfTextGroup, string> = {
  [BestOfTextGroup.Name]: 'Name',
  [BestOfTextGroup.Description]: 'Description',
  [BestOfTextGroup.NameAndDescription]: 'Name and description',
};

export const DisplayBestOfType: Record<BestOfType, string> = {
  [BestOfType.Rating]: 'Rating',
  [BestOfType.Count]: 'Count',
};

export const BestOf = () => {
  const { pendingRequest, apiCall, apiClient } = useApiCall();

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [activityType, setActivityType] = useState<ActivityType | null>(null);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [timeGroup, setTimeGroup] = useState<BestOfTimeGroup>(
    BestOfTimeGroup.All_Time,
  );
  const [textGroup, setTextGroup] = useState<BestOfTextGroup>(
    BestOfTextGroup.Name,
  );
  const [maxPerGroup, setMaxPerGroup] = useState<number>(10);
  const [bestOfType, setBestOfType] = useState<BestOfType>(BestOfType.Rating);
  const [results, setResults] = useState<Record<string, BestOfResult[]>>({});

  const canBestOf = (): boolean => !!activity || !!activityType;
  const onBestOf = async () => {
    const activitiesBestOf = await apiCall(apiClient =>
      apiClient.getActivitiesBestOf({
        activity_type_id: activityType?.id,
        activity_id: activity?.id,
        time_group: timeGroup,
        text_group: textGroup,
        best_of: bestOfType,
        ...(fromDate ? { start_date: fromDate } : {}),
        ...(toDate ? { end_date: toDate } : {}),
      }),
    );

    const groupedResults = groupResults(activitiesBestOf, {
      maxItemPerGroup: maxPerGroup,
    });
    setResults(groupedResults);
  };

  useHotkeys(
    'enter',
    async () => {
      if (canBestOf()) await onBestOf();
    },
    { enableOnFormTags: true },
    [fromDate, toDate, activityType],
  );

  return (
    <div>
      <PageHeader>
        <H2>Best Of</H2>
        <WrappingInputsContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="From"
              max={toDate}
              value={fromDate}
              onChange={event => setFromDate(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <DateInput
              placeholder="To"
              min={fromDate}
              value={toDate}
              onChange={event => setToDate(event.target.value)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivityTypeSelect
              placeholder="Type"
              apiClient={apiClient}
              isClearable
              value={activityType}
              onChange={o => setActivityType(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <ActivitySelect
              placeholder="Activity"
              apiClient={apiClient}
              isClearable
              value={activity}
              onChange={o => setActivity(o)}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <CustomSelect<EnumOption<BestOfTimeGroup>>
              getOptionLabel={a => DisplayBestOfTimeGroup[a.value]}
              getOptionValue={a => a.key}
              options={timeGroups}
              value={timeGroups.find(o => o.value === timeGroup)}
              onChange={event => {
                if (event) setTimeGroup(event.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <CustomSelect<EnumOption<BestOfTextGroup>>
              getOptionLabel={a => DisplayBestOfTextGroup[a.value]}
              getOptionValue={a => a.key}
              options={textGroups}
              value={textGroups.find(o => o.value === textGroup)}
              onChange={event => {
                if (event) setTextGroup(event.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <CustomSelect<EnumOption<BestOfType>>
              getOptionLabel={a => DisplayBestOfType[a.value]}
              getOptionValue={a => a.key}
              options={bestOfTypes}
              value={bestOfTypes.find(o => o.value === bestOfType)}
              onChange={event => {
                if (event) setBestOfType(event.value);
              }}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <MaxPerGroupInput
              type="number"
              value={maxPerGroup}
              min={0}
              onChange={event => setMaxPerGroup(Number(event.target.value))}
            />
          </HalfWidthContainer>
          <HalfWidthContainer>
            <BestOfButton onClick={onBestOf} disabled={!canBestOf()}>
              <FontAwesomeIcon icon={faSearch} />
            </BestOfButton>
          </HalfWidthContainer>
        </WrappingInputsContainer>
      </PageHeader>
      <PageContent>
        <BestOfContainer>
          {pendingRequest && <Spinner />}
          {!pendingRequest &&
            Object.entries(results)
              .sort(([a], [b]) => b.localeCompare(a))
              .map(([groupKey, groupItems]) => (
                <BestOfGroup
                  title={getGroupTitle(groupKey)}
                  bestOfResults={groupItems}
                />
              ))}
        </BestOfContainer>
      </PageContent>
    </div>
  );
};
