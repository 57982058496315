import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router';
import LhLogo from '../../assets/lh_logo_vector.svg';
import { IconButton, Input } from '../../common/styles';
import { buildApiClient } from '../../network/api-client';
import { storeCredentials } from '../../network/credentials-storage';
import {
  ErrorContainer,
  InputsContainer,
  LoginBox,
  LoginBoxContent,
  LoginContainer,
  Logo,
} from './styles';

export const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const onLogin = async () => {
    const apiClient = buildApiClient({ username, password });
    const result = await apiClient.authenticate();

    if (result.authenticate_result === 1) {
      storeCredentials({ username, password });
      navigate('/');
    } else setShowError(true);
  };

  useHotkeys('enter', onLogin, { enableOnFormTags: ['input'] }, [
    username,
    password,
  ]);

  return (
    <LoginContainer>
      <LoginBox>
        <LoginBoxContent>
          <Logo src={LhLogo} alt="logo" />
          <InputsContainer>
            <Input
              placeholder="Email"
              value={username}
              onChange={event => {
                setUsername(event.target.value);
                setShowError(false);
              }}
            />
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={event => {
                setPassword(event.target.value);
                setShowError(false);
              }}
            />
          </InputsContainer>
          <IconButton onClick={onLogin}>
            <FontAwesomeIcon icon={faRightToBracket} />
          </IconButton>
          {showError && <ErrorContainer>Invalid Credentials</ErrorContainer>}
        </LoginBoxContent>
      </LoginBox>
    </LoginContainer>
  );
};
