import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Input } from '../../common/styles';
import { ActivityTypeSelect } from '../../components/activity-type-select';
import { Spinner } from '../../components/spinner';
import { useApiCall } from '../../hooks/api-call-wrapper';
import { Activity, ActivityType, ReplaceActivity } from '../../models';
import {
  execReplaceActivity,
  getActivities,
  getActivityTypes,
} from '../../network/operations';
import { PageContent, PageHeader } from '../styles';
import { DisplayItems } from './display-items';
import { EditPanel } from './edit-panel';
import {
  EditingContainer,
  FilterButton,
  FiltersContainer,
  HeaderLineContainer,
  SpinnerContainer,
  ToggleButton,
} from './styles';

export enum ActivityEditMode {
  TYPES = 0,
  ACTIVITIES = 1,
  REPLACE_ACTIVITY = 2,
}

export type ListActivityItem = ActivityType | Activity;
export type EditActivityItem = ActivityType | Activity | ReplaceActivity;

export const Activities = () => {
  const { pendingRequest, apiCall, apiClient } = useApiCall();

  const [activityEditMode, setActivityEditMode] = useState<ActivityEditMode>(
    ActivityEditMode.TYPES,
  );
  const [activityItems, setActivityItems] = useState<ListActivityItem[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [filterActivityType, setFilterActivityType] =
    useState<ActivityType | null>(null);
  const [filterActive, setFilterActive] = useState<string>('');
  const [selectedActivityItem, setSelectedActivityItem] =
    useState<ListActivityItem>();

  // Util functions
  const toggleActivityEditMode = (mode: ActivityEditMode) => {
    if (activityEditMode === mode) return;
    setActivityItems([]);
    setFilterText('');
    setFilterActivityType(null);
    setFilterActive('');
    setSelectedActivityItem(undefined);
    setActivityEditMode(mode);
  };

  const updateItem = (newItems: ListActivityItem[]) =>
    setActivityItems(sortBy(newItems, i => i.name));

  const cancelEdit = () => setSelectedActivityItem(undefined);

  const onApplyFilter = () => {
    setFilterActive(filterText);
  };

  // Callbacks
  const onSelectionChange = useCallback((item: ListActivityItem) => {
    setSelectedActivityItem(item);
  }, []);

  const fetchItems = useCallback(async () => {
    const fetchedItems = await apiCall(async apiClient => {
      if (activityEditMode === ActivityEditMode.TYPES)
        return getActivityTypes(apiClient, filterActive);
      return getActivities(apiClient, filterActive, filterActivityType?.id);
    });
    updateItem(fetchedItems);
  }, [apiCall, activityEditMode, filterActive, filterActivityType]);

  const onCreate = useCallback(
    async (item: ListActivityItem) => {
      cancelEdit();
      let createdItem: ListActivityItem;
      if (activityEditMode === ActivityEditMode.TYPES) {
        createdItem = await apiCall(apiClient =>
          apiClient.addActivityType(item as ActivityType),
        );
      } else {
        createdItem = await apiCall(apiClient =>
          apiClient.addActivity(item as Activity),
        );
      }
      activityItems.push(createdItem);
      updateItem(activityItems);
    },
    [apiCall, activityEditMode, activityItems],
  );

  const onUpdate = useCallback(
    async (item: ListActivityItem) => {
      cancelEdit();
      let updatedItem: ListActivityItem;
      if (activityEditMode === ActivityEditMode.TYPES) {
        updatedItem = await apiCall(apiClient =>
          apiClient.updateActivityType(item as ActivityType),
        );
      } else {
        updatedItem = await apiCall(apiClient =>
          apiClient.updateActivity(item as Activity),
        );
      }
      const indexToReplace = activityItems.indexOf(selectedActivityItem!);
      activityItems[indexToReplace] = updatedItem;
      updateItem(activityItems);
    },
    [apiCall, selectedActivityItem, activityEditMode, activityItems],
  );

  const onDelete = useCallback(async () => {
    cancelEdit();
    if (activityEditMode === ActivityEditMode.TYPES) {
      await apiCall(apiClient =>
        apiClient.deleteActivityType(selectedActivityItem as ActivityType),
      );
    } else {
      await apiCall(apiClient =>
        apiClient.deleteActivity(selectedActivityItem as Activity),
      );
    }
    const indexToRemove = activityItems.indexOf(selectedActivityItem!);
    activityItems.splice(indexToRemove, 1);
    updateItem(activityItems);
  }, [apiCall, selectedActivityItem, activityEditMode, activityItems]);

  const onReplace = useCallback(
    async (replaceActivity: ReplaceActivity) => {
      cancelEdit();
      await apiCall(apiClient =>
        execReplaceActivity(apiClient, replaceActivity),
      );
      const indexToRemove = activityItems.indexOf(selectedActivityItem!);
      activityItems.splice(indexToRemove, 1);
      updateItem(activityItems);
    },
    [apiCall, selectedActivityItem, activityItems],
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useHotkeys('enter', onApplyFilter, { enableOnFormTags: true }, [filterText]);

  return (
    <div>
      <PageHeader>
        <HeaderLineContainer>
          <ToggleButton
            isActive={activityEditMode === ActivityEditMode.TYPES}
            onClick={() => toggleActivityEditMode(ActivityEditMode.TYPES)}
          >
            Types
          </ToggleButton>
          <ToggleButton
            isActive={activityEditMode === ActivityEditMode.ACTIVITIES}
            onClick={() => toggleActivityEditMode(ActivityEditMode.ACTIVITIES)}
          >
            Activities
          </ToggleButton>
        </HeaderLineContainer>
        <HeaderLineContainer>
          <FiltersContainer>
            <Input
              placeholder="Filter"
              value={filterText}
              onChange={event => setFilterText(event.target.value)}
            />
            {activityEditMode === ActivityEditMode.ACTIVITIES && (
              <ActivityTypeSelect
                placeholder="Type"
                apiClient={apiClient}
                isClearable
                value={filterActivityType}
                onChange={o => setFilterActivityType(o)}
              />
            )}
            <FilterButton onClick={onApplyFilter}>
              <FontAwesomeIcon icon={faFilter} />
            </FilterButton>
          </FiltersContainer>
        </HeaderLineContainer>
      </PageHeader>
      {pendingRequest && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {!pendingRequest && (
        <PageContent>
          <DisplayItems
            activityItems={activityItems}
            activityEditMode={activityEditMode}
            selectedActivityItem={selectedActivityItem}
            onSelectionChange={onSelectionChange}
          />
          <EditingContainer>
            <EditPanel
              activityEditMode={activityEditMode}
              activityItem={selectedActivityItem}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onReplace={onReplace}
              onDelete={onDelete}
              onCancel={cancelEdit}
            />
          </EditingContainer>
        </PageContent>
      )}
    </div>
  );
};
