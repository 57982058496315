import { forwardRef } from 'react';
import { Props as SelectProps, SelectInstance } from 'react-select';
import { Activity } from '../models';
import { ApiClient } from '../network/api-client';
import { CustomAsyncSelect } from './custom-select';

interface Props extends SelectProps<Activity, false> {
  apiClient: ApiClient;
}

export const ActivitySelect = forwardRef<SelectInstance<Activity>, Props>(
  (props, ref) => {
    const searchActivities = async (input: string): Promise<Activity[]> =>
      input.length >= 3 ? props.apiClient.searchActivities(input) : [];
    return (
      <CustomAsyncSelect<Activity>
        ref={ref}
        getOptionLabel={a => a.name}
        getOptionValue={a => a.id.toString()}
        loadOptions={searchActivities}
        {...props}
      />
    );
  },
);
