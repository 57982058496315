import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconButton } from '../../common/styles';
import { CustomSelect } from '../custom-select';
import {
  DateContainer,
  DateSelectContainer,
  MonthContainer,
  YearContainer,
} from './styles';

interface Props {
  value: Date;
  onChange: (date: Date) => void;
}

interface WrappedOption {
  value: number;
}

const wrapOptions = (items: number[]): WrappedOption[] =>
  items.map(m => ({ value: m }));

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};
const generateNumberArray = (size: number, startAt: number = 0) =>
  Array.from(Array(size + startAt).keys()).slice(startAt);

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const MONTHS_OPTIONS: WrappedOption[] = wrapOptions(generateNumberArray(12));
const YEAR_OPTIONS: WrappedOption[] = wrapOptions(
  generateNumberArray(100, 2010),
);

export const DateSelect = ({ value, onChange }: Props) => {
  const dateOptions = wrapOptions(
    generateNumberArray(
      getDaysInMonth(value.getFullYear(), value.getMonth()),
      1,
    ),
  );

  const previousDay = () => {
    onChange(
      new Date(value.getFullYear(), value.getMonth(), value.getDate() - 1),
    );
  };

  const nextDay = () => {
    onChange(
      new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1),
    );
  };

  useHotkeys('arrowleft', previousDay, {}, [value, onChange]);
  useHotkeys('arrowright', nextDay, {}, [value, onChange]);

  return (
    <DateSelectContainer>
      <IconButton onClick={previousDay}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>
      <DateContainer>
        <CustomSelect<WrappedOption>
          value={{ value: value.getDate() }}
          getOptionLabel={o => o.value.toString()}
          onChange={o => {
            onChange(new Date(value.getFullYear(), value.getMonth(), o!.value));
          }}
          options={dateOptions}
        />
      </DateContainer>
      <MonthContainer>
        <CustomSelect<WrappedOption>
          value={{ value: value.getMonth() }}
          getOptionLabel={o => MONTH_NAMES[o.value]}
          onChange={o => {
            onChange(new Date(value.getFullYear(), o!.value, value.getDate()));
          }}
          options={MONTHS_OPTIONS}
        />
      </MonthContainer>
      <YearContainer>
        <CustomSelect<WrappedOption>
          value={{ value: value.getFullYear() }}
          getOptionLabel={o => o.value.toString()}
          onChange={o => {
            onChange(new Date(o!.value, value.getMonth(), value.getDate()));
          }}
          options={YEAR_OPTIONS}
        />
      </YearContainer>
      <IconButton onClick={nextDay}>
        <FontAwesomeIcon icon={faArrowRight} />
      </IconButton>
    </DateSelectContainer>
  );
};
